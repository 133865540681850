/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px !important;
  height: 595px !important;
  z-index: 1100;
}
.modal-overlay_one {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 800px !important;
  height: 620px !important;
  /* height: max-content; */
  z-index: 1100;
  /* overflow: scroll; */
  overflow-x: hidden;
}

/* Modal Content */
.modal-content {
  background-color: white;
  border-radius: 0;
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  /* height: 95vh !important; */
  overflow-y: auto;
}

.modal-body .cart_table_mix thead tr th {
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
}

/* Modal Icon */
.modal-icon {
  font-size: 24px;
  color: #fff;
  background: #219653;
  border-radius: 50%;
  padding: 4px;
  width: 35px;
  height: 35px;
}

/* Modal Close Button */
.modal-close-btn {
  background-color: #e0e0e0;
  color: #828282;
  border: none;
  border-radius: 0;
  width: 94px;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  /* margin-top: 20px; */
  transition: background-color 0.3s;
}

.modal-submit-btn {
  margin-left: 15px;
  background-color: #efc441;
  color: #fff;
  border: none;
  border-radius: 0;
  width: 112px;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  /* margin-top: 20px; */
  transition: background-color 0.3s;
}

/* Modal Text */
.modal-body h2 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
  line-height: 18px;
}
.modal-body {
  margin-top: 10px;
}
.modal-body .top_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-body .ingredients_details {
  margin-top: 33px;
}
.modal-body .ingredients_details h6 {
  font-size: 16px;
  font-weight: 500;
}
.modal-body .ingredients_details span {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
/* .modal-body .ingredients_details button, */
.read-more-button {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
  border: none;
  /* background: #828282; */
  color: rgb(83, 83, 209);
}
.modal-body .ingredients_details .dose_modal {
  margin-top: 30px;
}
.modal-body p {
  color: #828282;
}
.no-scroll {
  overflow: hidden !important;
}

.modal-body.slidecontainer {
  width: 100%;
}

.modal-body.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.modal-body.slider:hover {
  opacity: 1;
}
.range_slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.range_slider .form-range {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  background: #efc441;
  outline: none;
}
.range_slider .form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #efc441;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 25%);
}

.range_slider .range_input {
  border: 1px solid #e0e0e0;
  font-size: 14px;
  padding: 10px;
}
.recommended_dose,
.total_price {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recommended_dose h5,
.total_price h5 {
  font-size: 16px;
  line-height: 19px;
}

.recommended_dose h5:nth-child(1) {
  color: #333333 !important;
  font-size: 16px;
  line-height: 19px;
}

.total_price h5:nth-child(2) {
  color: #4f4f4f;
}
.recommended_dose h5:nth-child(1) {
  color: #333333 !important;
  font-size: 16px;
  line-height: 19px;
}
.cart_item_table {
  width: 100%;
  height: 350px !important;
  overflow: scroll;
  overflow-x: hidden;
}
