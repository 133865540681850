.small_spinner {
  /* width: 36px;
  height: 36px; */
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid white;
  animation: spin 600ms linear infinite;
  display: inline-block;
}
.spinner {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid white;
  animation: spin 600ms linear infinite;
  display: inline-block;
}

.small_spinner-cart {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #efc441;
  animation: spin 600ms linear infinite;
  display: inline-block;
}
.spinner-cart {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #efc441;
  animation: spin 600ms linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader_container {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
