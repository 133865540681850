body {
  margin: 0;
  /* font-family: "Rubik", sans-serif; */
  font-family: 'Montserrat', 'sans-serif';
  color: #333;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  outline: none;
}

input, textarea {
  font-family: 'Montserrat', 'sans-serif';
}

.container {
  width: 100%;
  max-width: 1270px;
  padding: 0 15px;
  margin: 0 auto;
}

input {
  -webkit-user-scalable: no;
}


/* This rule sets the thumb color for Firefox */
input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #efc441 !important;
  border: 2px solid #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 25%);
  cursor: pointer;
}

/* This rule sets the track color for Firefox */
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: #efc441;
  border-radius: 5px;
}

input,
textarea {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

button {
  cursor: pointer;
  border-radius: 8px !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.row {
  margin: 0 -15px;
}

:root {
  --yellow: #f2c94c;
}

/* CSS code to set default color of input range to #EFC441 */
/* .input-range input[type="range"] {
  appearance: none;
  width: 100%;
  height: 6px !important;
  background-color: #efc441;
} */

/* For Webkit browsers like Chrome and Safari */
.input-range input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #efc441;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 25%);
}

/* For Firefox browser */
.input-range input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background-color: #d9d9d9;
  cursor: pointer;
}

.c-badge {
  border-radius: 10px;
  padding: 5px 24px;
  font-size: 14px;
  font-weight: 500;
  min-width: 133px;
  text-align: center;
}

/* filter popup */
.filter-main {
  width: 500px;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 111;
  height: 100vh;
  /* opacity: 0;
    visibility: hidden; */
}

.filter-main .filter-head {
  width: 100%;
  display: flex;
  padding: 24px 24px 21px 19px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 7%);
}

.filter-main .filter-head h2 {
  font-weight: 500;
  font-size: 20px;
}

.filter-main .filter-head button {
  background: none;
  border: none;
}

.filter-main .product-summary {
  width: 100%;
}

.filter-main .product-summary .payment-summary {
  width: 100%;
  padding: 16px 19px;
}

.filter-main .product-summary .payment-summary h3 {
  font-weight: 500;
  font-size: 18px;
  color: #000;
  margin: 0 0 20px;
}

.filter-main .product-summary .payment-summary ul li {
  display: flex;
  align-items: center;
  margin: 0 0 9px;
  justify-content: space-between;
}

.filter-main .product-summary .payment-summary ul li span {
  font-size: 18px;
  color: #4f4f4f !important;
  font-weight: normal;
}

.filter-main .product-summary .payment-summary ul li span strong {
  color: #bdbdbd;
  font-weight: normal;
  margin: 0 0 0 6px;
}

.filter-main .product-summary .total-price {
  width: 100%;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 23px 19px;
}

.filter-main .product-summary .total-price h5,
.filter-main .product-summary .total-price .total {
  font-weight: 600;
  font-size: 18px;
  color: #4f4f4f;
}

thead tr th {
  font-weight: 500;
  font-size: 14px;
  color: #828282;
  padding: 13px 19px;
}

table,
tr,
td,
thead,
th {
  border-collapse: collapse;
}

tbody tr td h4 {
  font-weight: 500;
  font-size: 16px;
  color: #000;
  margin: 0 0 8px;
}

tbody tr td p {
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}

tbody tr td p span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.product-img {
  width: 60px;
  padding: 0 15px;
}

.product-img img {
  width: 60px;
  height: 60px;
  border-radius: 20px;
}

.quantity {
  color: #4f4f4f !important;
}

.price {
  color: #000 !important;
  font-size: 16px;
  font-weight: normal !important;
}

/* add card popup */
.add-address-main,
.add-rate-main,
.reason-cancellation-main {
  width: 500px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 111;
}

.add-address-main.address-main-active {
  opacity: 1;
  visibility: visible;
}

.add-address-main {
  height: 92vh;
  overflow-y: auto;
  border-radius: 10px;
}

.add-address-main .head,
.add-rate-main .head,
.reason-cancellation-main .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 40px 21px 35px;
  border-bottom: 1px solid #e0e0e0;
}

.add-address-main .head {
  padding: 21px 40px 21px 42px;
}

.add-address-main .head h2,
.add-rate-main .head h2,
.reason-cancellation-main .head h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
}

.add-address-main .card-details {
  width: 100%;
  padding: 22px 40px 43px;
}

.card-details {
  width: 100%;
  padding: 22px 37px 43px;
}

.add-rate-main .rate,
.reason-cancellation-main .rate {
  width: 100%;
  padding: 22px 40px 0;
}

.add-rate-main .rate .input-field,
.reason-cancellation-main .rate .input-field {
  width: 100%;
  margin: 0 0 35px;
}

.reason-cancellation-main .rate .input-field {
  margin: 0 0 124px;
}

.add-rate-main .rate .input-field span {
  display: flex;
  align-items: center;
  gap: 7px;
}

.add-rate-main .rate .input-field label,
.reason-cancellation-main .rate .input-field label {
  font-weight: 500;
  font-size: 14px;
  color: #333;
  margin: 0 0 9px;
  display: block;
}

.add-rate-main .rate .input-field textarea,
.reason-cancellation-main .rate .input-field textarea {
  width: 100%;
  height: 120px;
  padding: 11px 22px;
  color: #4f4f4f;
  line-height: 20px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
}

.add-address-main .card-details .input-field {
  width: 100%;
  margin: 0 0 14px;
}

.card-details .input-field {
  width: 100%;
  margin: 0 0 14px;
}

.card-details .input-field label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin: 0 0 5px;
  text-align: justify;
}

.add-address-main .card-details .input-field label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin: 0 0 5px;
}

.card-details .input-field input {
  width: 100%;
  height: 50px;
  border: 1px solid #e0e0e0;
  padding: 0 22px;
}

.add-address-main .card-details .input-field input,
.add-address-main .card-details .input-field select,
.add-address-main .card-details .input-field textarea {
  width: 100%;
  height: 50px;
  border: 1px solid #e0e0e0;
  padding: 0 10px;
  border-radius: 8px;
}

.add-address-main .card-details .input-field textarea {
  height: 80px;
  padding: 16px 22px 0;
}

.add-address-main .card-details .input-field input::placeholder,
.add-address-main .card-details .input-field textarea::placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.add-address-main .card-details .input-field .input-address {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 9px;
  margin: 14px 0 15px;
}

.add-address-main .card-details .input-field .input-address input {
  width: 17px;
  height: 17px;
}

.add-address-main .card-details .input-field .input-address label {
  color: #4f4f4f;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.add-address-main .head button.close-btn,
.add-rate-main .head button.close-btn,
.reason-cancellation-main .head button.close-btn {
  background: none;
  border: none;
}

.add-address-main .card-btn,
.add-rate-main .card-btn,
.reason-cancellation-main .card-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 26px;
  border-top: 1px solid #e0e0e0;
}

.add-address-main .card-btn {
  background-color: #fff;
  position: absolute;
  bottom: 0;
}

.add-address-main .card-details {
  height: 80vh;
  overflow-y: auto;
}

/* .add-address-main .card-btn {
  position: fixed;
  bottom: 0;
  left: 0;
} */
.add-address-main .card-btn button,
.add-rate-main .card-btn button,
.reason-cancellation-main .card-btn button {
  background-color: #e0e0e0;
  border: none;
  color: #828282;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  width: 121px;
  height: 40px;
}

.add-address-main .card-btn button.save-btn,
.add-rate-main .card-btn button.save-btn,
.reason-cancellation-main .card-btn button.save-btn {
  background-color: #efc441;
  color: #fff;
}

/* cart item popup */
.cart-items-main {
  width: 1151px;
  background-color: #fff;
  padding: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  height: 90vh;
}

.total-price {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.total-price {
  color: #eb5757;
  font-size: 16px;
  font-weight: bold;
}

.cart-item-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0;
}

.cart-item-head h2 {
  font-weight: 500;
  font-size: 20px;
}

.cart-item-head button {
  background: none;
  border: none;
}

.checkout-cart {
  background-color: #fff !important;
}

.cart-items-main .cart-item-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-items-main .cart-item-button button {
  width: 140px;
  height: 50px;
  background-color: #e0e0e0;
  color: #828282;
  line-height: 18px;
  font-size: 16px;
  font-weight: 600;
  border: none;
}

.cart-items-main .cart-item-button button.add-cart {
  background-color: #efc441;
  color: #fff;
}

/* ingredients confirmation popup */
.ingredients-confirmation-main {
  width: 500px;
  padding: 29px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
}

.ingredients-confirmation-main .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ingredients-confirmation-main .head h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
}

.ingredients-confirmation-main .head h2 span {
  color: #828282;
  font-size: 16px;
  line-height: 18px;
}

.ingredients-confirmation-main .head p {
  color: #eb5757;
  font-weight: 500;
  line-height: 20px;
  font-size: 20px;
}

.ingredients-confirmation-main .about {
  width: 100%;
  margin: 33px 0;
}

.ingredients-confirmation-main .about label {
  font-weight: 500;
  font-size: 16px;
  color: #4f4f4f;
}

.ingredients-confirmation-main .about p {
  color: #828282;
  line-height: 20px;
  margin: 8px 0 0;
}

.ingredients-confirmation-main .dose {
  width: 100%;
}

.ingredients-confirmation-main .dose p {
  color: #4f4f4f;
  margin: 0 0 15px;
}

.ingredients-confirmation-main .dose p span {
  color: #828282;
}

.ingredients-confirmation-main .dose .range {
  width: 100%;
  display: flex;
  gap: 41px;
  align-items: center;
  margin: 0 0 28px;
}

.ingredients-confirmation-main .dose .range .input-range {
  width: 100%;
}

.ingredients-confirmation-main .dose .range .input-range input {
  width: 340px;
}

.ingredients-confirmation-main .dose .range .range-count {
  width: 60px;
}

.ingredients-confirmation-main .dose .range .range-count input {
  width: 100%;
  height: 36px;
  padding: 0 0 0 12px;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
}

.ingredients-confirmation-main .recommended {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 16px;
}

.ingredients-confirmation-main .recommended span.heading {
  font-size: 16px;
}

.ingredients-confirmation-main .recommended span.gram {
  color: #828282;
  line-height: 20px;
  font-size: 16px;
}

.ingredients-confirmation-main .recommended .total,
.ingredients-confirmation-main .recommended .price {
  color: #4f4f4f;
  font-weight: 500;
  font-size: 16px;
}

.ingredients-confirmation-main .recommended .price {
  color: #333;
  line-height: 20px;
}

.ingredients-confirmation-main .ingrediants-btn {
  width: 100%;
  margin: 40px 0 0;
  display: flex;
  justify-content: right;
  gap: 11px;
}

.ingredients-confirmation-main .ingrediants-btn button {
  width: 94px;
  height: 40px;
  background-color: #e0e0e0;
  color: #828282;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  border: none;
}

.ingredients-confirmation-main .ingrediants-btn button.add-mix {
  background-color: #efc441;
  color: #fff;
  width: 112px;
}

/* CSS code to set default color of input range to #EFC441 */

/* flavor popup */
.flavor-main,
.add-sweetners-main,
.addmix-ingredients-main {
  width: 900px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 11;
  overflow-y: auto;
  visibility: visible;
  height: 90vh;
  z-index: 1111;
  border-radius: 10px;
}

.mix-ingrediants-range-main {
  width: 100%;
  /* height: 360px;
  overflow-y: scroll;
  overflow-x: hidden; */
}

/* Scrollbar hide */
.mix-ingrediants-range-main::-webkit-scrollbar {
  width: 0.5em;
}

/* .mix-ingrediants-range-main::-webkit-scrollbar-track {
  background-color: transparent;
}

.mix-ingrediants-range-main::-webkit-scrollbar-thumb {
  background-color: transparent;
} */

.mix-ingrediants-range {
  width: 100%;
  display: flex;
}

.flavor-main .flavor-head,
.add-sweetners-main .flavor-head,
.addmix-ingredients-main .flavor-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 29px 40px 20px;
  border-bottom: 1px solid #e0e0e0;
  margin: 0 0 42px;
}

.add-sweetners-main .flavor-head button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 4px;
}

.addmix-ingredients-main .flavor-head {
  margin: 0;
}

.addmix-ingredients-main .flavor-head .search-bar {
  width: 100%;
  position: relative;
  margin: 14px 0 0;
}

.addmix-ingredients-main .flavor-head .search-bar input {
  width: 100%;
  height: 50px;
  background-color: #f2f2f2;
  color: #333;
  border: none;
  padding: 0 47px;
  border-radius: 10px;
}

.addmix-ingredients-main .flavor-head .search-bar input::placeholder {
  color: #bdbdbd;
  font-size: 14px;
}

.addmix-ingredients-main .flavor-head .search-bar span {
  position: absolute;
  top: 16px;
  left: 18px;
}

.flavor-main .flavor-head h2,
.add-sweetners-main .flavor-head h2,
.addmix-ingredients-main .flavor-head h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
}

.flavor-main .flavor-head h2 p,
.add-sweetners-main .flavor-head h2 p,
.addmix-ingredients-main .flavor-head h2 p {
  font-size: 14px;
  line-height: 18px;
  color: #828282;
  font-weight: normal;
}

.flavor-main .flavor-head button,
.addmix-ingredients-main .flavor-head button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 3px;
}

.addmix-ingredients-main .addmix-ingredients-head {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 18px 40px 21px;
  border-bottom: 1px solid #e0e0e0;
  margin: 0 0 26px;
  gap: 300px;
}

.addmix-ingredients-main .addmix-ingredients-head h3,
.addmix-ingredients-main .addmix-ingredients-head span {
  font-weight: 500;
  font-size: 16px;
}

.addmix-ingredients-main .addmix-ingredients-head span span {
  color: #bdbdbd;
  font-weight: normal;
}

.flavor-main .flavor-select,
.add-sweetners-main .flavor-select,
.addmix-ingredients-main .flavor-select {
  width: 100%;
  padding: 0 40px;
}

.add-sweetners-main .flavor-select {
  height: 430px;
  overflow-y: auto;
}

.addmix-ingredients-main .flavor-select {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 0 15px;
}

.flavor-main .flavor-select .flavor-category,
.add-sweetners-main .flavor-select .flavor-category,
.addmix-ingredients-main .flavor-select .flavor-category {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 38px;
}

.addmix-ingredients-main .flavor-select .flavor-category {
  margin: 0 0 0;
}

.addmix-ingredients-main .flavor-head .gender-btn {
  width: 100%;
  display: flex;
  gap: 20px;
  margin: 25px 0 0;
}

.addmix-ingredients-main .flavor-head .gender-btn button {
  width: 145px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 7px;
  border: 1px solid #dbdbdb;
  color: #4f4f4f;
  font-weight: 500;
  font-size: 15px;
  font-family: "Rubik", sans-serif;
  position: relative;
}

.cart-order-num {
  font-size: 12px;
  width: 16px;
  height: 17px;
  background-color: #eb5757;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -4px;
  left: 16px;
}

.addmix-ingredients-main .flavor-head .gender-btn button .mixing-dropdown {
  display: none;
  width: 100%;
  position: absolute;
  top: 32px;
  left: 0;
}

.addmix-ingredients-main .flavor-head .gender-btn button .mixing-dropdown.active {
  display: block;
  width: 100%;
  position: absolute;
  top: 32px;
  left: 0;
}

.addmix-ingredients-main .flavor-head .gender-btn button .mixing-dropdown ul {
  width: 100%;
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid #efc441;
  border-top: none;
  padding: 8px 10px;
}

.addmix-ingredients-main .flavor-head .gender-btn button .mixing-dropdown ul li {
  color: #595959;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid #dbdbdb;
  padding: 9px 0;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
}

.addmix-ingredients-main .flavor-head .gender-btn button .mixing-dropdown ul li:last-child {
  border: none;
}

.addmix-ingredients-main .flavor-select .flavor-category {
  justify-content: flex-start;
  width: 48%;
}

.mix-ingrediants-range {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  gap: 20px;
  height: 37vh;
  overflow-y: auto;
}

.mix-ingrediants-range .dose {
  width: 52%;
}

.mix-ingrediants-range .dose .range {
  width: 100%;
  gap: 16px;
  display: flex;
  align-items: center;
  /* margin: 0 0 69px; */
}

.mix-ingrediants-range .dose .range .input-range {
  width: 300px;
  margin-top: 12px;
  accent-color: #efc441;
}

.mix-ingrediants-range .dose .range .input-range p {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.mix-ingrediants-range .dose .range .input-range input {
  width: 100% !important;
}

.mix-ingrediants-range .dose .range .range-count {
  width: max-content;
  display: flex;
  align-items: center;
}

.mix-ingrediants-range .dose .range .range-count input {
  width: 38px;
  /* height: 36px; */
  border: 1px solid #e0e0e0;
  /* padding: 0 0 0 12px; */
  border-radius: 6px;
}

.mix-ingrediants-range .dose .range .range-count span {
  margin: 0 0 0 5px;
}

.flavor-main .flavor-select .flavor-category .field,
.add-sweetners-main .flavor-select .flavor-category .field,
.addmix-ingredients-main .flavor-select .flavor-category .field {
  width: 40%;
  display: flex;
  align-items: center;
}

.addmix-ingredients-main .flavor-select .flavor-category .field {
  width: 100%;
}

.flavor-main .flavor-select .flavor-category .field input,
.add-sweetners-main .flavor-select .flavor-category .field input,
.addmix-ingredients-main .flavor-select .flavor-category .field input {
  width: 18px;
  height: 18px;
  accent-color: #efc441;
  border: 1px solid #999;
}

.addmix-ingredients-main .flavor-select .flavor-category .field input[type="checkbox"],
.flavor-main .flavor-select .flavor-category .field input[type="checkbox"],
.add-sweetners-main .flavor-select .flavor-category .field input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
}

.addmix-ingredients-main .flavor-select .flavor-category .field input[type="checkbox"]:checked,
.flavor-main .flavor-select .flavor-category .field input[type="checkbox"]:checked,
.add-sweetners-main .flavor-select .flavor-category .field input[type="checkbox"]:checked {
  background-color: #efc441;
}

.addmix-ingredients-main .flavor-select .flavor-category .field input[type="checkbox"]::before,
.flavor-main .flavor-select .flavor-category .field input[type="checkbox"]::before,
.add-sweetners-main .flavor-select .flavor-category .field input[type="checkbox"]::before {
  content: "\2714";
  display: block;
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  line-height: 18px;
}

.flavor-main .flavor-select .flavor-category .field label,
.add-sweetners-main .flavor-select .flavor-category .field label,
.addmix-ingredients-main .flavor-select .flavor-category .field label {
  color: #4f4f4f;
  font-size: 16px;
  margin: 0 0 0 12px;
}

.flavor-main .flavor-btn,
.add-sweetners-main .flavor-btn,
.addmix-ingredients-main .flavor-btn {
  width: 100%;
  border-top: 1px solid #e0e0e0;
  padding: 30px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0;
  background-color: #fff;
  position: absolute;
  bottom: 0;
}

/* .addmix-ingredients-main .flavor-btn {
  position: absolute;
  bottom: 0;
  left: 0;
} */

.flavor-main .flavor-btn button,
.add-sweetners-main .flavor-btn button,
.addmix-ingredients-main .flavor-btn button {
  width: 121px;
  height: 40px;
  color: #828282;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  background-color: #e0e0e0;
  border: none;
}

.flavor-main .flavor-btn button.save-btn,
.add-sweetners-main .flavor-btn button.save-btn,
.addmix-ingredients-main .flavor-btn button.save-btn {
  background-color: #efc441;
  color: #fff;
}

.add-sweetners-main .flavor-btn .sweetner-btn .sweetback-btn {
  border: 1px solid #bdbdbd;
  background-color: #fff;
  margin: 0 8px 0 0;
}

/* offers-main popup */
.offers-main {
  width: 500px;
  background-color: #fff;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 26px 40px 49px;
  opacity: 0;
  visibility: hidden;
  z-index: 111;
  border-radius: 10px;
}

.offers-main.offer-active {
  opacity: 1;
  visibility: visible;
}

.offers-main h2 {
  margin: 19px 0 9px;
  font-size: 20px;
  line-height: 18px;
  font-weight: 600;
}

.offers-main p {
  line-height: 22px;
  color: #828282;
  margin: 0 0 20px;
}

.offers-main ul {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.offers-main ul li.active-offer {
  background-color: #f5fdff;
  border: 1px solid #56ccf2;
}

.offers-main ul li.active-offer .days .default-offer {
  background-color: #34d2ff;
  border: none;
  color: #fff;
}

.offers-main ul li {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f2f2f2;
  height: 44px;
  padding: 13px 19px;
  cursor: pointer;
  border-radius: 8px;
}

.offers-main ul li .days {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

.offers-main ul li .days .default-offer {
  width: 50px;
  height: 18px;
  border: 1px solid #219653;
  color: #219653;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
}

.offers-main ul li .price {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.offers-main ul li .price .cutoff {
  color: #828282;
  text-decoration-line: line-through;
  margin: 0 11px 0 0;
}

.offers-main .continue-btn {
  width: 100%;
  margin: 31px 0 0;
}

.offers-main .continue-btn button {
  width: 100%;
  height: 44px;
  background-color: #efc441;
  border: none;
  color: #fff;
  font-weight: 600;
  line-height: 18px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

/* logout-main */
.logout-main,
.success-main,
.cart-main,
.cart-sucess-main,
.password-update-main,
.rating-confirmation-main,
.cancle-confirmation-main,
.delete-profile-main {
  width: 400px;
  text-align: center;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 44px 0 61px;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 111;
  border-radius: 10px;
}

.cart-main {
  width: 350px;
  padding: 20px 0 30px;
}

.logout-main {
  padding: 20px 0 30px;
  width: 310px;
}

.cart-sucess-main.active {
  width: 400px;
  text-align: center;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 44px 0 61px;
  transform: translate(-50%, -50%);
  opacity: 111;
  visibility: visible;
  z-index: 111;
}

.success-main.success-active {
  opacity: 1;
  visibility: visible;
}

.success-main {
  padding: 44px 38px 61px;
}

.logout-main.logout-active {
  opacity: 1;
  visibility: visible;
}

.logout-main-one.logout-active {
  opacity: 1;
  visibility: visible;
}

.logout-main h2,
.success-main h2,
.cart-main h2,
.cart-sucess-main h2,
.password-update-main h2,
.rating-confirmation-main h2,
.cancle-confirmation-main h2,
.delete-profile-main h2 {
  font-size: 20px;
  line-height: 18px;
  margin: 20px 0 0;
  font-weight: 600;
}

.logout-main p,
.success-main p,
.cart-main p,
.cart-sucess-main p,
.password-update-main p,
.rating-confirmation-main p,
.cancle-confirmation-main p,
.delete-profile-main p {
  color: #828282;
  line-height: 20px;
  margin: 15px 0 29px;
  padding: 0 30px;
}

.logout-main .logout-btn,
.success-main .logout-btn,
.cart-main .logout-btn,
.cart-sucess-main .login-btn,
.password-update-main .login-btn,
.rating-confirmation-main .login-btn,
.cancle-confirmation-main .logout-btn,
.delete-profile-main .logout-btn {
  width: 100%;
}

.logout-main .logout-btn button,
.success-main .logout-btn button,
.cart-main .logout-btn button,
.cart-sucess-main .logout-btn button,
.password-update-main .logout-btn button,
.rating-confirmation-main .logout-btn button,
.cancle-confirmation-main .logout-btn button,
.delete-profile-main .logout-btn button {
  width: 94px;
  height: 40px;
  background-color: #e0e0e0;
  color: #828282;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  border: none;
  margin: 0 10px 0 0;
}

.cart-main .logout-btn button.yes-btn {
  background-color: #efc441;
  color: white;
}

.cart-main.active {
  opacity: 1;
  visibility: visible;
}

.logout-main .logout-btn button.yes-btn,
.success-main .logout-btn button.ok-btn,
.cart-sucess-main .logout-btn button.yes-btn,
.password-update-main .logout-btn button.yes-btn,
.rating-confirmation-main .logout-btn button.yes-btn,
.cancle-confirmation-main .logout-btn button.yes-btn,
.delete-profile-main .logout-btn button.yes-btn {
  background-color: #efc441;
  color: #fff;
}

.delete-profile-main .logout-btn button.yes-btn {
  background-color: #eb5757;
  color: #fff;
}

/* profile dropdown */
.profile-main {
  width: 230px;
  background-color: #fff;
  box-shadow: 3px 0px 16px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 18px 0 0;
  opacity: 0;
  position: absolute;
  top: 210px;
  border-radius: 7px;
  z-index: 11;
  left: -57px;
  visibility: hidden;
  transform: translate(-50%, -50%);
}

.profile-main.profile-active {
  opacity: 1;
  visibility: visible;
}

.profile-main span img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.profile-main span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-main span .user-profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #e8e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-main h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 13px 0 0;
}

.profile-main a {
  font-size: 14px;
  color: #828282;
}

.profile-main .profile-menu {
  width: 100%;
  text-align: left;
  border-top: 1px solid #c4c4c4;
  padding: 18px 20px 21px;
  margin: 21px 0 0;
}

.profile-main .profile-menu ul {
  display: flex !important;
  flex-wrap: wrap;
  gap: 10px !important;
}

.profile-main .profile-menu ul li:last-child {
  padding-bottom: 0 !important;
}

.profile-main .profile-menu ul li a {
  color: #4f4f4f;
  font-size: 16px;
  font-weight: 500;
  display: flex !important;
  align-items: center;
}

.profile-main .profile-menu ul li a .profile_icon {
  display: flex;
}

.profile-main .profile-menu ul li a .profile_icon img {
  width: 15px;
  margin: 0 14px 0 0;
}

.profile-main .profile-menu ul li a img {}

.profile-main .logout-btn {
  width: 100%;
  text-align: left;
  padding: 16px 20px 22px;
  border-top: 1px solid #c4c4c4;
}

.profile-main .logout-btn a {
  font-weight: 500;
  font-size: 16px;
  color: #eb5757;
  display: flex !important;
  align-items: center;
  gap: 13px;
}

.address-heading {
  color: black;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 14px;
}

.empty-cart-container {
  width: 100%;
  background-color: white;
  margin: auto;
  /* height: 60vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 55px 30px;
  border-radius: 10px;
}

.empty-cart-container>div {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* overlay */
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 30%);
  opacity: 0;
  visibility: hidden;
  z-index: 11;
}

.back-btn-one a {
  color: #828282;
}

.overlay_one {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(177, 172, 172, 0.5);
  z-index: 1000;
  pointer-events: auto;
}

.overlay.offer-active {
  opacity: 1;
  visibility: visible;
}

.overlay.success-active {
  opacity: 1;
  visibility: visible;
}

.overlay.logout-active {
  opacity: 1;
  visibility: visible;
}

.overlay.reset-active {
  opacity: 1;
  visibility: visible;
}

.overlay.otp-active {
  opacity: 1;
  visibility: visible;
}

.overlay.password-active {
  opacity: 1;
  visibility: visible;
}

.overlay.forgot-active {
  opacity: 1;
  visibility: visible;
}

.overlay.register-active {
  opacity: 1;
  visibility: visible;
}

.overlay.active {
  opacity: 1;
  visibility: visible;
}

/* login popup css start */

.login-main.active {
  opacity: 1;
  visibility: visible;
}

.forgot-main.forgot-active {
  opacity: 1;
  visibility: visible;
}

.login-main,
.register-main,
.forgot-main,
.verify-mail-main,
.new-password-main,
.complete-reset {
  width: 100%;
  max-width: 500px;
  padding: 40px 40px 58px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 111;
  border-radius: 10px;
}

.register-main {
  height: 90vh;
  overflow-y: auto;
}

/* Scrollbar hide */
.register-main::-webkit-scrollbar {
  width: 0.5em;
}

.register-main::-webkit-scrollbar-track {
  background-color: transparent;
}

.register-main::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.complete-reset.reset-active {
  opacity: 1;
  visibility: visible;
}

.new-password-main.password-active {
  opacity: 1;
  visibility: visible;
}

.verify-mail-main.otp-active {
  opacity: 1;
  visibility: visible;
}

.register-main.register-active {
  opacity: 1;
  visibility: visible;
}

.login-main .logo,
.register-main .logo,
.forgot-main .logo,
.verify-mail-main .logo,
.new-password-main .logo,
.complete-reset .logo {
  width: 100%;
  /* position: relative; */
}

.login-main .logo span,
.register-main .logo span,
.forgot-main .logo span,
.verify-mail-main .logo span,
.new-password-main .logo span,
.complete-reset .logo span {
  position: absolute;
  top: -42px;
  right: 22px;
  cursor: pointer;
}

.login-main .logo span img,
.register-main .logo span img,
.forgot-main .logo span img,
.verify-mail-main .logo span img,
.new-password-main .logo span img,
.complete-reset .logo span img {
  position: absolute;
  color: #bdbdbd;
  font-size: 20px;
  top: 100px;
  right: 19px;
  cursor: pointer;
}

.login-main .logo a,
.register-main .logo a,
.forgot-main .logo a,
.verify-mail-main .logo a,
.new-password-main .logo a,
.complete-reset .logo a {
  display: inline-block;
}

.login-main .logo a,
.register-main .logo a,
.forgot-main .logo a,
.verify-mail-main .logo a,
.new-password-main .logo a,
.complete-reset .logo a {
  background-color: transparent !important;
  color: transparent !important;
}

.login-main .logo a img,
.register-main .logo a img,
.forgot-main .logo a img,
.verify-mail-main .logo a img,
.new-password-main .logo a img,
.complete-reset .logo a img {
  width: 150px;
  height: 43px;
  object-fit: contain;
  vertical-align: top;
}

.login-main .login-field,
.register-main .login-field,
.forgot-main .login-field,
.verify-mail-main .login-field,
.new-password-main .login-field,
.complete-reset .login-field {
  width: 100%;
  margin: 35px 0 0;
}

.login-main .login-field h2,
.register-main .login-field h2,
.forgot-main .login-field h2,
.verify-mail-main .login-field h2,
.new-password-main .login-field h2,
.complete-reset .login-field h2 {
  font-weight: 600;
  font-size: 22px;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.complete-reset .login-field h2 {
  gap: 11px;
}

.forgot-main .login-field h2 {
  display: unset;
  width: auto;
}

/* .login-main .login-field h2 img , .register-main .login-field h2, .forgot-main .login-field h2, .verify-mail-main .login-field h2  {
    width: 28px;
    height: 30px;
    object-fit: contain;
} */

.login-main .login-field p,
.register-main .login-field p,
.forgot-main .login-field p,
.verify-mail-main .login-field p,
.new-password-main .login-field p,
.complete-reset .login-field p {
  color: #828282;
  margin: 9px 0 25px;
}

.login-main .login-field .input-field,
.register-main .login-field .input-field,
.forgot-main .login-field .input-field,
.verify-mail-main .login-field .input-field,
.new-password-main .login-field .input-field {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;
}

.input-field .height-devide {
  width: 100%;
  display: flex;
  gap: 10px;
}

.input-field .height-devide .input {
  position: relative;
  width: 100%;
}

.input-field .height-devide span {
  font-size: 14px;
  color: #9a9a9a;
  font-weight: normal;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 40%;
}

.autocomplete-dropdown {
  border-radius: 10px;
  line-height: 20px;
  cursor: pointer;
  margin-top: 2px;
  padding: 6px 0px 0px 5px;
  font-size: 15px;
}

.register-main .login-field .input-field {
  margin: 0 0 10px;
}

.register-main .login-field .input-field .country-btn {
  width: 70px;
  border: 1px solid #e0e0e0;
  border-right: none;
  height: 50px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
}

.register-main .login-field .input-field .phone-input {
  width: calc(100% - 70px);
}

.register-main .login-field .input-field .select-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  font-size: 14ppx;
  color: #151515;
}

.login-main .login-field .input-field .eye-btn,
.register-main .login-field .input-field .eye-btn,
.new-password-main .input-field .eye-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  color: #828282;
  background: none;
  border: none;
}

.login-main .login-field .input-field input,
.register-main .login-field .input-field input,
.register-main .login-field .input-field .select-btn,
.forgot-main .login-field .input-field input,
.verify-mail-main .login-field .input-field input,
.new-password-main .login-field .input-field input {
  border: 1px solid #e0e0e0;
  width: 100%;
  height: 50px;
  padding: 0 22px;
  border-radius: 8px;
  background: #fff;
}

.verify-mail-main .login-field .input-field input {
  padding: 0 0 0 23px;
}

.login-main .login-field .input-field input::placeholder,
.register-main .login-field .input-field input::placeholder .verify-mail-main .login-field .input-field input::placeholder,
.new-password-main .login-field .input-field input::placeholder {
  font-size: 14ppx;
  color: #bdbdbd;
}

.login-main .login-field .input-field .input-check,
.register-main .login-field .input-field .input-check,
.new-password-main .login-field .input-field .input-check {
  width: auto;
  display: flex;
  align-items: center;
  margin: 0 0 21px;
}

.register-main .login-field .input-field .input-check {
  align-items: self-start;
}

.login-main .login-field .input-field .input-check input[type="checkbox"],
.register-main .login-field .input-field input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.register-main .login-field .input-field input[type="checkbox"] {
  margin: 3px 0 0;
}

.login-main .login-field .input-field .input-check label,
.register-main .login-field .input-field .input-check label {
  color: #828282;
  margin: 0 0 0 15px;
}

.login-main .login-field .input-field .input-check label a,
.register-main .login-field .input-field .input-check label a {
  margin: 0;
}

.login-main .login-field .input-field a,
.register-main .login-field .input-field a {
  color: #34d2ff;
  display: inline-block;
  margin: 0 0 21px;
}

.login-main .login-field .input-field .verify-otp,
.register-main .login-field .input-field .verify-otp,
.verify-mail-main .login-field .input-field .verify-otp {
  display: flex;
  justify-content: space-around;
  gap: 15px;
}

.login-main .login-field .input-field .verify-otp input[type="text"],
.register-main .login-field .input-field .verify-otp input[type="text"] {
  width: 16.66%;
  padding: 0 0 0 22px;
}

.login-main .login-btn,
.register-main .login-btn,
.forgot-main .login-btn,
.verify-mail-main .login-btn,
.new-password-main .login-btn,
.complete-reset .login-btn {
  width: 100%;
  display: inline-block;
}

.login-main .login-btn a,
.register-main .login-btn a,
.forgot-main .login-btn a,
.verify-mail-main .login-btn a,
.new-password-main .login-btn a,
.complete-reset .login-btn a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  width: 100%;
  height: 50px;
  background-color: #efc441;
  margin: 10px 0 0;
  border-radius: 8px;
}

.login-main .log-footer,
.register-main .log-footer,
.verify-mail-main .log-footer {
  width: 100%;
  display: inline-block;
  margin: 24px 0 0;
  text-align: center;
}

.login-main .for-verify p,
.register-main .for-verify p,
.verify-mail-main .for-verify p {
  margin: 0 0 6px;
}

.login-main .log-footer p,
.register-main .log-footer p,
.verify-mail-main .log-footer p {
  color: #828282;
}

.login-main .log-footer p a,
.register-main .log-footer p a,
.verify-mail-main .log-footer p a {
  color: #34d2ff;
  margin: 0 0 0 5px;
  cursor: pointer;
}

.login-main .back-login,
.register-main .back-login,
.forgot-main .back-login,
.verify-mail-main .back-login,
.new-password-main .back-login {
  width: 100%;
  text-align: center;
  margin: 22px 0 0;
}

.login-main .back-login a,
.register-main .back-login a,
.forgot-main .back-login a,
.verify-mail-main .back-login a,
.new-password-main .back-login a {
  color: #828282;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.login-main .back-login a img,
.forgot-main .back-login a img,
.register-main .back-login a img,
.verify-mail-main .back-login a img,
.new-password-main .back-login a img {
  margin: 0 5px 0 0;
}

/* header css start */
#header-main {
  width: 100%;
  display: inline-block;
  box-shadow: 0 0 2px rgba(0, 0, 0, 25%);
  padding: 0 50px;
}

.header-sticky {
  width: 100%;
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 11;
}

.header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.header .logo {
  width: 15%;
}

.header .logo a {
  display: inline-block;
  background-color: transparent;
  color: transparent;
}

.header .logo a img {
  width: auto;
  height: auto;
}

.header nav {
  width: 35%;
  padding: 0px;
  text-align: right;
}

.header nav ul button.close-btn {
  background: none;
  border: none;
  display: none;
}

.header nav ul li {
  display: inline-block;
  padding: 0 20px;
  /* padding: 12px 25px; */
}


.header nav ul input {
  height: 30px;
  padding: 0 10px;
  display: none;
  border: 1px solid #bdbdbd;
}

.header nav ul li a {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.header nav ul li a.active {
  color: #efc441;
}

.header .user {
  width: auto;
}

.header .user ul {
  display: flex;
  gap: 36px;
  align-items: center;
}

.header .user ul .search_icon_mobile {
  display: none;
}

.header .user ul li {
  display: inline-block;
  padding: 0 0;
  position: relative;
  cursor: pointer;
}

.header .user ul li.bar-btn {
  display: none;
}

.header .user ul li.cart-icon {
  padding: 6px 0 0;
  position: relative;
}

.header .user ul li.bar-btn button {
  background: none;
  border: none;
  display: none;
}

.header .user ul li:nth-child(4) {
  padding-right: 0;
}

/* .header .user ul li.user-profile-main {
    display: none;
} */

.header .user ul li a {
  display: inline-block;
}

.header .user ul li a.user-btn {
  padding: 5px 0 0;
}

.header .user ul li a.user-profile {
  width: 40px;
  height: 40px;
  /* display: none; */
}

.header .user ul li a.user-profile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.user-profile-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #e8e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* banner css start */
#banner-main {
  width: 100%;
  position: relative;
  border-top: 1px solid #e6e3e3;
}

.banner-img {
  width: 100%;
  display: inline-block;
}

.banner-img img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  vertical-align: top;
}

.banner-text {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.banner-text .banner {
  width: 100%;
  max-width: 600px;
}

.banner-text .banner-design {
  max-width: none;
}

.banner-text .banner h1 {
  color: #fff;
  font-weight: bold;
  font-size: 52px;
}

.banner-text .banner a {
  width: 135px;
  height: 44px;
  background-color: #efc441;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 32px 0 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  gap: 5px;
  border-radius: 8px;
}

.banner-text a img {
  margin: 0 9px 0 0;
}

/* shop main css start */
#shop-main {
  width: 100%;
  display: inline-block;
  margin: 63px 0 92px;
}

.shop-mobile {
  display: none;
  width: 100%;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu input {
  width: 80%;
  padding: 8px;
  margin: 10px auto;
  display: block;
}

/* Optional: Style the close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.mobile-menu a {
  text-decoration: none;
  color: #333;
  /* Adjust the text color as needed */
  font-size: 16px;
  /* Adjust the font size as needed */
  display: block;
}

.mobile-menu li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  /* Add a border between menu items */
}

.mobile-menu {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
}

.mobile-menu.open {
  display: block;
}

.shop-header-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.shop-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 43px;
}

.shop-header h2 {
  font-size: 36px;
  font-weight: 600;
}

.shop-header h2 p {
  font-size: 16px;
  line-height: 20px;
  color: #4f4f4f;
  font-weight: normal;
  margin: 6px 0 0;
}

#product-main .shop-header a {
  width: 192px;
}

.shop-header a {
  width: 120px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4f4f4f;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  border: 1px solid #828282;
  border-radius: 8px;
}

.shop-header a img {
  margin: 0 0 0 7px;
}

.shop-category-col {
  width: 40%;
  float: left;
  padding: 0 15px;
}

.shop-muscle-col {
  width: 60%;
  float: left;
  padding: 0 15px;
}

.shop-body-col {
  width: 30%;
  float: left;
  padding: 0 15px;
}

.shop-category {
  width: 100%;
  display: inline-block;
  margin: 0 0 27px;
  position: relative;
}

.shop-category a img {
  width: 100%;
  height: 315px;
  object-fit: cover;
}

.shop-category .shop-redirect {
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
}

.shop-category .shop-redirect a.redirect-btn {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.shop-category .shop-redirect a img {
  width: 16px;
  height: 16px;
}

.shop-category .shop-redirect span {
  font-weight: 600;
  font-size: 24px;
  color: #fff;
}

/* product main css start */
#product-main {
  width: 100%;
  display: inline-block;
  background-color: #f2f2f2;
  padding: 80px 0 107px;
}

.product-main-slider {
  width: 100%;
  display: none;
}

.product-col {
  width: 25%;
  float: left;
  padding: 0 15px;
}

.product {
  width: 100%;
  position: relative;
  background-color: #fff;
  margin: 0 0 26px;
  height: 450px;
  border-radius: 20px;
  cursor: pointer;
}

.product:hover {
  border: 1px solid #efc441;
  position: relative;
  top: -10px;
 transition: all 0.5s;
}

.product {
  width: 100%;
  position: relative;
  background-color: #fff;
  margin: 0 0 26px;
  height: 428px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.5s;
}

.mix-scratch-product .product {
  height: 438px;
}

.extra-input-class{
  padding: 0px 8px !important;
  width: 100% !important;
  margin-top: 12px !important;
}
.extra-input-class input{
  padding: 18px 0px;

}
.extra-ingredients-input{
    width: 105px;
    height: 36px;
    border: 1px solid #e0e0e0;
    padding: 0 0 0 12px;
    border-radius: 6px;
    display: flex;
    align-items: center
}
.product .product-img {
  width: 100%;
  text-align: center;
  display: inline-block;
}

.product .product-img img {
  margin: 20px 0;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.product .like-btn {
    background: none;
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
}

.product .like-btn img {
  width: 28px;
}

.product .detail {
  width: 100%;
  padding: 0 30px;
}

.product .detail span {
  font-size: 14px;
  line-height: 18px;
  color: #828282;
}

.product .detail h3 {
  font-size: 16px;
  line-height: 18px;
  margin: 4px 0 10px;
  font-weight: 600;
}

.product .detail .rating {
  font-size: 14px;
  color: #828282;
  display: flex;
  align-items: center;
}

.product .rating img {
  margin: 0 9px 0 0;
}

.product-detail {
  width: 100%;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 8%);
  padding: 29px 0 21px;
  margin: 23px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 30px 16px 30px;
  position: absolute;
  bottom: 0;
}

.product-detail span {
  color: #333;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  padding: 0;
}
.product-sell-main  .product .product-detail span {
  color: #333;
}
.rangePrice {
  color: #eb5757;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  padding: 0;
}

.product-detail button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #34d2ff;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
}

.product-detail button img {
  margin: 0 9px 0 0;
}

/* customer main css start */
#customers-main {
  width: 100%;
  display: inline-block;
  position: relative;
}

.customer-bg-img {
  width: 100%;
}

.customer-bg-img img {
  width: 100%;
  height: 580px;
  object-fit: cover;
}

.customers-details-main {
  width: 100%;
  position: absolute;
  top: 62px;
  left: 0;
}

.customer-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.customer-head {
  width: 100%;
  margin: 0 0 61px;
}

.customer-head h2 {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}

.customer-slider-main {
  width: 100%;
  display: inline-block;
}

.customer-details-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.customer-details {
  width: 100%;
  background-color: #fff;
  padding: 38px 64px 42px 46px;
  height: 320px;
}

.customer-details span {
  display: flex;
}

.customer-details span img {
  width: 24px;
}

.customer-details p {
  margin: 18px 0 0;
  color: #4f4f4f;
  line-height: 26px;
  font-weight: 500;
  min-height: 135px;
}

.customer-details .customer-profile {
  width: 100%;
  display: flex;
  gap: 19px;
  align-items: center;
}

.customer-details .customer-profile .profile {
  width: 70px;
}

.customer-details .customer-profile .profile img {
  width: 100%;
  height: 70px;
  border-radius: 50%;
}

.customer-details .customer-profile .username {
  width: calc(100% - 70px);
}

.customer-details .customer-profile .username h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
}

.customer-details .customer-profile .username span {
  color: #4f4f4f;
  font-size: 12px;
  color: #4f4f4f;
}

.customer-slider-main .slick-prev {
  left: inherit;
  right: 70px;
  top: -80px;
}

.customer-slider-main .slick-next {
  right: 15px;
  top: -80px;
}

.customer-slider-main .slick-prev,
.customer-slider-main .slick-next {
  width: 46px;
  height: 46px;
  border: 1px solid #fff;
}

/* footer main css start */

#footer-main {
  width: 100%;
  display: inline-block;
  margin: 15px 0 0;
}

.footer-top-main {
  width: 100%;
  display: inline-block;
  padding: 0 0 25px;
  box-shadow: 0 7px 8px rgba(0, 0, 0, 10%);
}

.footer-top-row {
  display: flex;
  justify-content: space-between;
}

.footer-top-col {
  width: auto;
  float: left;
  padding: 0 15px;
}

.footer-top {
  width: 100%;
}

.footer-top.ml {
  padding: 0 0 0 125px;
}

.footer-top h4 {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  margin: 5px 0;
}

.footer-top p {
  font-size: 14px;
  color: #4f4f4f;
}

.footer-middle {
  width: 100%;
  display: inline-block;
  padding: 54px 0 65px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #e0e0e0;
}

.footer-logo-col {
  width: 30%;
  float: left;
  padding: 0 15px;
}

.footer-logo-main {
  width: 100%;
  display: inline-block;
}

.footer-logo-main .footer-logo {
  display: block;
}

.footer-logo-main .footer-logo img {
  width: 161px;
  /* height: 54px; */
}

.footer-logo-main a.number {
  font-size: 25px;
  line-height: 20px;
  color: #333;
  margin: 34px 0 11px;
  display: block;
}

.footer-logo-main a {
  font-size: 18px;
  line-height: 20px;
  color: #828282;
}

.footer-logo-main .social-link {
  width: 100%;
  margin: 46px 0 0;
}

.footer-logo-main .social-link span {
  color: #4f4f4f;
  font-size: 20px;
  font-weight: 700;
}

.footer-logo-main .social-link ul {
  width: auto;
  margin: 19px 0 0;
  display: flex;
  gap: 27px;
  align-items: center;
}

.footer-logo-main .social-link ul li {
  display: inline-block;
}

.footer-col {
  width: 23%;
  float: left;
  padding: 0 15px;
}

.footer {
  width: 100%;
}

.footer.pl {
  padding: 0 0 0 115px;
}

.footer h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 17px;
}

.footer ul li a {
  font-size: 14px;
  line-height: 30px;
  color: #4f4f4f;
}

.footer-bottom-main {
  width: 100%;
}

.footer-bottom-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.footer-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 26px;
}

.footer-bottom p {
  font-size: 14px;
  color: #828282;
}

.footer-bottom a {
  font-size: 14px;
  line-height: 30px;
  color: #828282;
  margin: 0 0 0 36px;
}

/* product-selling-main css start */
#product-selling-main {
  width: 100%;
  display: inline-block;
  margin: 84px 0 97px;
}

#product-selling-main h2 {
  font-weight: 600;
  font-size: 36px;
  margin: 0 0 39px;
}

#product-selling-main h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

#product-selling-main .price {
  color: #eb5757;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
}

.selling-col {
  width: 33.33%;
  float: left;
  padding: 0 15px;
}

.selling-product {
  width: 100%;
}

.selling-product .best-product {
  width: 100%;
  display: flex;
  margin: 0 0 20px;
}

.selling-product .best-product .product-img {
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
}

.selling-product .best-product .product-label {
  width: calc(100% - 160px);
  padding: 29px 0 32px 32px;
  border: 1px solid #e0e0e0;
  margin: 0;
}

.selling-product .best-product .product-label .rating {
  font-size: 14px;
  color: #828282;
  display: flex;
  margin: 10px 0 24px;
  align-items: center;
}

.selling-product .best-product .product-label .rating img {
  margin: 0 9px 0 0;
}

.new-arrival-col {
  width: 33.33%;
  float: left;
  padding: 0 15px;
}

.new-arrival {
  width: 100%;
  border: 1px solid #e0e0e0;
}

.new-arrival .arrival-img {
  width: 100%;
  display: block;
  padding: 10px 0 0;
  text-align: center;
}

.new-arrival .arrival-img img {
  width: 100%;
}

.new-arrival .rating {
  display: flex;
}

.new-arrival h3 {
  margin: 0 0 13px;
}

.new-arrival h3,
.new-arrival .rating {
  padding: 0 30px 0 42px;
}

.new-arrival .rating img {
  width: 17px;
}

.new-arrival .arrival-price {
  width: 100%;
  margin: 28px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 30px 26px 42px;
  border-top: 1px solid #e0e0e0;
}

.new-arrival .arrival-price button.cart-btn {
  color: #34d2ff;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 11px;
}

.new-arrival-product .slick-prev:before,
.new-arrival-product .slick-next:before {
  color: #333;
}

.new-arrival-product .slick-prev,
.new-arrival-product .slick-next {
  top: -60px;
  right: 15px;
}

.new-arrival-product .slick-prev {
  right: 74px;
  left: inherit;
}

.new-arrival-product .slick-prev:before,
.new-arrival-product .slick-next:before {
  font-size: 36px;
}

/* mix-product main css start */

.mix-main {
  width: 100%;
  display: inline-block;
  padding: 72px 0 82px;
  background-color: #f2f2f2;
}

.create-mix-main {
  width: 100%;
}

.create-mix-main h2 {
  font-weight: 600;
  font-size: 30px;
  margin: 0 0 30px;
}

.create-mix-main .create-mix {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 40px;
}

.create-mix-main .create-mix .create {
  width: 50%;
  padding: 26px 30px 31px 45px;
  border: 1px solid #e0e0e0;
  min-height: 200px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
}

.create-mix-main .create-mix .create.active {
  border: 1px solid #efc441;
  visibility: visible;
}

.create-mix-main .create-mix .create .creta-head {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
}

.create-mix-main .create-mix .create .creta-head input {
  width: 35px;
  height: 35px;
  appearance: none;
  border: 1px solid;
  border-radius: 50%;
}

.create-mix-main .create-mix .create.active .creta-head input {
  accent-color: #efc441;
}

.create-mix-main .create-mix .create .creta-head input::after {
  content: "";
  position: absolute;
  top: -2px;
  right: -2px;
  width: 40px;
  height: 40px;
  background-image: url(../../public/images/radio_btn_active.svg);
  background-repeat: no-repeat;
  opacity: 0;
  visibility: hidden;
  background-size: 100%;
}

.create-mix-main .create-mix .create .creta-head input:checked::after {
  opacity: 1;
  visibility: visible;
  border: none;
}

.create-mix-main .create-mix .create h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  margin: 24px 0 11px;
}

.create-mix-main .create-mix .create p {
  line-height: 22px;
  color: #4f4f4f;
}

.create-mix-main .create-mix .create p span {
  font-size: 20px;
}

.create-mix-main .select-category {
  width: 100%;
  margin: 40px 0 0;
}

.create-mix-main .select-category h3,
.create-mix-main .qna-main h3 {
  font-weight: 600;
  font-size: 22px;
  margin: 0 0 29px;
}

.select-category h3 {
  font-weight: 600;
  font-size: 22px;
  margin: 0 0 29px;
}

.create-mix-main .select-category h3 span {
  color: #4f4f4f;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  padding: 0 0 0 11px;
}

.create-mix-main .select-category ul {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.create-mix-main .select-category ul li {
  border: 1px solid #bdbdbd;
  background-color: #fff;
  padding: 18px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  border-radius: 10px;
}

.create-mix-main .select-category ul li.active {
  color: #efc441;
}

.create-mix-main .qna-main {
  width: 100%;
  margin: 40px 0 0;
}

.create-mix-main .qna-main h3 {
  margin: 0 0 27px;
}

.create-mix-main .qna-main ul li {
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #e0e0e0;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 10px;
}

.create-mix-main .qna-main ul li a {
  color: #4f4f4f;
}

.create-mix-main .qna-main ul li .selectoption {
  display: flex;
  width: auto;
  gap: 39px;
  align-items: center;
}

.create-mix-main .qna-main ul li .selectoption .input-field {
  width: auto;
  display: flex;
  align-items: center;
  gap: 7px;
}

.create-mix-main .qna-main ul li .selectoption .input-field input {
  width: 20px;
  height: 20px;
}

.create-mix-main .submit-btn {
  width: 100%;
  display: flex;
  justify-content: right;
}

.create-mix-main .submit-btn button {
  width: 140px;
  height: 50px;
  background-color: #efc441;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  margin: 41px 0 0;
}

.creat-mix-overview {
  width: 100%;
  display: none;
}

.creat-mix-overview.active {
  display: inline-block;
}

.creat-mix-overview ul li {
  cursor: pointer;
}

.filter-mix-main {
  width: 100%;
  display: flex;
  gap: 30px;
  margin: 50px 0 0;
}

.filter-mix-main .mix-filter-main {
  width: 302px;
}

.filter-mix-main .mix-filter-main h2 {
  font-weight: 600;
  font-size: 20px;
  margin: 2px 0 28px;
}

.filter-mix-main .mix-filter-main .filter {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
}

.filter-mix-main .mix-filter-main .filter .gender {
  width: 100%;
  padding: 25px 25px 27px;
}

.filter-mix-main .mix-filter-main .filter .gender button,
.filter-mix-main .mix-filter-main .filter .height-weight button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 14px;
  font-size: 16px;
}

.filter-mix-main .mix-filter-main .filter .height-weight select {
  border-radius: 10px;
}

.filter-mix-main .mix-filter-main .filter .gender .input-field {
  width: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 0 15px;
  position: relative;
}

.filter-mix-main .mix-filter-main .filter .gender .input-field input {
  width: 18px;
  height: 18px;
  border: 1px solid #bdbdbd;
  appearance: none;
  border-radius: 50%;
}

.filter-mix-main .mix-filter-main .filter .gender .input-field input.active::after {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  width: 20px;
  height: 20px;
  background-image: url(../../public/images/radio_btn_active.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

.filter-mix-main .mix-filter-main .filter .gender .input-field input.active:checked::after {
  opacity: 1;
  visibility: visible;
  border: none;
}

.filter-mix-main .mix-filter-main .filter .gender .input-field input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
}

.filter-mix-main .mix-filter-main .filter .gender .input-field input[type="checkbox"]:checked {
  background-color: #efc441;
}

.filter-mix-main .mix-filter-main .filter .gender .input-field input[type="checkbox"]::before {
  content: "\2714";
  display: block;
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  line-height: 18px;
}

.custom-checkbox {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.custom-checkbox:checked {
  background-color: #efc441;
}

.custom-checkbox::before {
  content: "\2714";
  display: block;
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  line-height: 18px;
}

.filter-mix-main .mix-filter-main .filter .gender .input-field label {
  color: #4f4f4f;
  font-size: 14px;
}

.filter-mix-main .mix-filter-main .filter .height-weight {
  width: 100%;
  border-top: 1px solid #f2f2f2;
  padding: 27px 27px 31px;
}

.filter-mix-main .mix-filter-main .filter .height-weight button {
  width: 100%;
}

.filter-mix-main .mix-filter-main .filter .height-weight input {
  width: 100%;
  border: 1px solid #e0e0e0;
  height: 6px;
  /* padding: 0 19px; */
}

.filter-mix-main .mix-filter-main .filter .height-weight input::placeholder {
  color: #bdbdbd;
  font-size: 16px;
}

.filter-mix-main .mix-scartch-main {
  width: calc(100% - 302px);
}

.filter-mix-main .mix-scartch-main .mix-scartch-head {
  width: 100%;
  display: flex;
  margin: 0 0 15px;
  justify-content: space-between;
  align-items: center;
}

.filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scartch-left {
  width: auto;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 0px 0px 0px 0;
}

.pages-btn {
  border: 1px solid orange;
  border-radius: 5px;
  padding: 0;
  margin: 0px;
  color: #333;
  font-size: 15px;
  cursor: pointer;
}

.pages-btn.active-page {
  border: 1px solid #efc441;
  color: #efc441;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  border: 1px solid #efc441;
  color: #efc441;
  background-color: rgb(245 245 237);
  font-weight: 600;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  background-color: #f9f9f9;
  font-weight: 600;
  box-sizing: border-box;
  min-width: 45px;
  height: 40px;
  padding: 0px 13px;
  margin: 3px 3px;
  border-radius: 4px !important;
}

.previous-btn {
  background-color: #919eab !important;
  border: none !important;
}

.pagination-btn,
.pages-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 2px solid #dfe3e8;
  border-radius: 5px;
  background: #fff;
}

.filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scartch-left h2 {
  font-weight: 500;
  font-size: 20px;
  display: flex;
  width: max-content;
  margin: 0;
}

.filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scartch-left h2 span {
  color: #219653;
  margin: 0 0 0 26px;
  font-size: 16px;
  display: flex;
  width: 221px;
}

.filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scartch-left h2 span img {
  margin: 0 7px 0 0;
  width: 20px;
}

.filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scratch-right {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 44px;
  justify-content: right;
}

.filter-mix-main .mix-scartch-main .product-mix-list .mix-scratch-right p {
  font-weight: 400;
}

.filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scratch-right .cart-btn {
  background: #efc441;
  border: none;
  position: fixed;
  z-index: 11;
  top: 85%;
  right: 3%;
  border: 3px solid #efc441;
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  box-shadow: 0 0px 20px #efc441;
}

.filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scratch-right .cart-btn .order-num {
  width: 20px;
  height: 20px;
  background-color: #eb5757;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -4px;
  left: 27px;
}

.filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scratch-right .search-bar {
  width: 264px;
  position: relative;
}

.filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scratch-right .search-bar span {
  position: absolute;
  top: 10px;
  left: 0;
}

.filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scratch-right .search-bar input {
  width: 100%;
  height: 40px;
  border: none;
  background: none;
  padding: 0 30px;
  border-bottom: 1px solid #e0e0e0;
}

.filter-mix-main .mix-scartch-main .mix-scratch {
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
}

.filter-mix-main .mix-scartch-main .mix-scratch-product {
  background-color: transparent;
}

table tr,
th,
td,
thead,
tbody {
  border-collapse: collapse;
}

table thead {
  border-bottom: 1px solid #e0e0e0;
}

table thead tr th {
  padding: 20px 45px;
  font-weight: 500;
  font-size: 16px;
  color: #828282;
  text-align: left;
  width: 40.33%;
}

table tbody tr td {
  width: 43%;
}

table tbody tr td.table-col,
table tbody tr td.price,
table tbody tr td.add-btn {
  padding: 20px 0;
}
table tbody tr td.add-btn .give-review-btn {
  color: #219653 !important;
  font-weight: 500 !important;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  display: flex;
  justify-content: center;
  width: -webkit-fill-available;
  align-items: center;
  gap: 0;
}

table tbody tr td.table-col,
table tbody tr td.price,
table tbody tr td.add-btn img {
  margin: 0 9px 0 0;
}

table tbody tr td.table-col {
  font-size: 16px;
  line-height: 16px;
  color: #000;
}

table tbody tr td.table-col span {
  color: #828282;
}

table tbody tr td.price {
  font-weight: 500;
  color: #4f4f4f;
}

table tbody tr td button {
  color: #34d2ff;
  font-weight: 500;
  background: none;
  border: none;
  width: max-content;
  font-size: 16px;
}

/* product-details main css start */

#product-details-main {
  width: 100%;
  display: inline-block;
  margin: 40px 0 0;
}

.back-btn {
  width: auto;
  margin: 0 0 40px;
}

.back-btn a {
  width: max-content;
  color: #000;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.product-bio-main {
  width: 100%;
  display: flex;
  gap: 75px;
}

.product-bio-main .product-profile {
  width: 900px;
}

.product-bio-main .product-profile span {
  width: 100%;
  height: 450px;
  display: flex;
  align-items: self-start;
  justify-content: center;
  /* background-color: #f2f2f2; */
  border-radius: 10px;
}

.product-bio-main .product-profile ul {
  display: flex;
  gap: 15px;
  margin: 19px 0 0;
  width: 100%;
}

.product-bio-main .product-profile ul li {
  width: 135px;
  height: 85px;
  border: 1px solid #bdbdbd;
  height: 78px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.product-bio-main .product-profile ul li.active {
  border: 1px solid #efc441;
}

.product-bio-main .product-profile ul li img {
  width: 120px;
  height: 75px;
}

.product-bio-main .product-profile span {
  position: relative;
}

.product-bio-main .product-profile span img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
}

.product-bio-main .product-profile span img.wishlist-icon {
  position: absolute;
  top: 15px;
  right: 17px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.product-bio-main .product-bio {
  width: calc(100% - 450px);
}

.product-bio-main .product-bio span.price {
  margin-top: 13px !important;
}

.product-bio-main .product-bio h2 {
  font-weight: 600;
  font-size: 30px;
}

.product-bio-main .product-bio span.rating {
  font-size: 14px;
  color: #828282;
  display: flex;
  align-items: center;
  gap: 7px;
  margin: 12px 0 41px;
}

.product-bio-main .product-bio span.price {
  font-size: 26px;
  font-weight: 600;
  line-height: 18px;
  color: #333 !important;
  width: 100%;
  display: block;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 0 39px;
  margin: 0 0 32px;
}

.product-bio-main .product-bio p {
  color: #4f4f4f;
  line-height: 26px;
}

.product-bio-main .product-bio p.category {
  margin: 36px 0 17px;
}

.product-bio-main .product-bio p label {
  font-weight: 600;
}

.product-bio-main .product-bio span.quantity-label {
  font-size: 18px;
  font-weight: 500;
  display: block;
  margin: 36px 0 24px;
}

.product-bio-main .product-bio .quantity {
  width: auto;
  display: flex;
  gap: 31px;
}

.product-bio-main .product-bio .quantity button.quantity-count {
  background: none;
  display: flex;
  align-items: center;
  height: 50px;
  border: 1px solid #e0e0e0;
}

.product-bio-main .product-bio .quantity button span {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-bio-main .product-bio .quantity button span.quantity-number {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.product-bio-main .product-bio .quantity button.cart-btn {
  width: 180px;
  height: 50px;
  background-color: #efc441;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}

.product-label {
  width: 100%;
  margin: 100px 0 0;
}

.product-label ul {
  width: 100%;
  display: flex;
  gap: 59px;
  margin: 0 0 40px;
  border-bottom: 1px solid #e0e0e0;
}

.product-label ul li {
  font-weight: 500;
  cursor: pointer;
  font-size: 22px;
  padding: 0 0 21px;
  border: 2px solid transparent;
  line-height: 18px;
  color: #828282;
}

.product-label .discription {
  width: 100%;
}

.product-label .discription p {
  line-height: 26px;
  color: #4f4f4f;
}

.product-label .discription h3 {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #4f4f4f;
  margin: 33px 0 19px;
}

.product-label .discription ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  border: none;
  display: none;
}

.product-label ul li.active-bio {
  border-bottom: 4px solid #efc441;
  color: #333;
}

.product-label .discription ul.active-bio {
  display: unset;
}

.product-label .discription ul.active-bio .display-discription {
  display: flex;
  flex-wrap: wrap;
}

.product-label .discription ul.active-bio .display-nutrients {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 20px 20px 22px 20px;
  border: 1px solid #e3e2e2;
  border-radius: 10px;
}

.product-label .discription ul.active-bio .display-nutrients .inner-nutrients {
  width: 147px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  row-gap: 10px;
  flex-wrap: wrap;
}

.product-label .discription ul.active-bio .display-nutrients .head {
  font-weight: bold;
}

.product-label .discription ul.active-bio .display-nutrients>div {
  width: 100%;
}

.product-label .discription ul li {
  width: 16.66%;
  margin: 0 0 30px;
  padding: 0 50px 0 0;
}

.product-label .discription ul li label {
  font-weight: 500;
  font-size: 14px;
  color: #4f4f4f;
}

.display-nutrients {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-label .discription ul li p {
  font-size: 14px;
  color: #828282;
  margin: 4px 0 0;
  line-height: normal;
}

.product-label .discription ul .rating-review-main {
  width: 100%;
}

.product-label .discription ul .all-reviews {
  width: 100%;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 20px;
}

.product-label .discription ul .all-reviews .customer-review {
  width: 32% !important;
  margin: 0 !important;
}

.product-label .discription ul .rating-review-main .rating-review {
  width: 100%;
  display: flex;
  margin: 0 0 35px;
  gap: 64px;
  align-items: center;
}

.product-label .discription ul .rating-review-main .rating-review .product-rating {
  text-align: center;
}

.product-label .discription ul .rating-review-main .rating-review .product-rating>span {
  font-size: 20px;
  display: block;
  font-weight: bold;
}

/* .product-label
  .discription
  ul
  .rating-review-main
  .rating-review
  .product-rating {
  width: 152px;
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.product-label
  .discription
  ul
  .rating-review-main
  .rating-review
  .product-rating
  span {
  color: #333;
  font-weight: 500;
  font-size: 25px;
  display: flex;
  width: 100%;
  gap: 9px;
  justify-content: left;
  align-items: center;
}
.product-label .discription ul .rating-review-main .rating-review .product-rating span.total-review h4 {
  color: #828282;
  font-size: 12px;
  font-weight: normal;
  margin: 3px 0 0;
}

.product-label
  .discription
  ul
  .rating-review-main
  .rating-review
  .product-rating
  p {
  color: #828282;
  font-size: 12px;
  margin: 3px 0 0;
}

.product-label
  .discription
  ul
  .rating-review-main
  .rating-review
  .percent-rating {
  width: 20%;
}

.product-label
  .discription
  ul
  .rating-review-main
  .rating-review
  .percent-rating
  span {
  color: #4f4f4f;
  font-size: 12px;
  display: flex;
  gap: 11px;
  margin: 0 0 6px;
  align-items: center;
}

.product-label
  .discription
  ul
  .rating-review-main
  .rating-review
  .percent-rating
  span
  label {
  display: inline-block;
  width: auto;
}

.product-label
  .discription
  ul
  .rating-review-main
  .rating-review
  .percent-rating
  span.percent-line {
  width: 120px;
  height: 8px;
  background-color: #d9d9d9;
  border-radius: 15px;
  position: relative;
}

.product-label
  .discription
  ul
  .rating-review-main
  .rating-review
  .percent-rating
  span.percent-line
  .percent-line-after {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 8px;
  border-radius: 15px 0px 0px 15px;
  background-color: #efc441;
}

/* .product-label .discription ul .rating-review-main .rating-review .percent-rating span.percent-line::after,
.product-label .discription ul .rating-review-main .rating-review .percent-rating span.poor-percent::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 8px;
  border-radius: 15px 0px 0px 15px;
  background-color:  #d9d9d9;
} */

.product-label .discription ul .rating-review-main .rating-review .percent-rating span.poor-percent::after {
  width: 10px;
  background-color: #eb5757;
}

.product-label .discription ul .rating-review-main .customer-review {
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 27px 41px 32px;
  margin: 0 0 15px;
  border-radius: 10px;
}

.product-label .discription ul .rating-review-main .customer-review span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.product-label .discription ul .rating-review-main .customer-review span img {
  width: 15px;
}

.product-label .discription ul .rating-review-main .customer-review p {
  color: #4f4f4f;
  line-height: 26px;
  margin: 16px 0 12px;
}

.product-label .discription ul .rating-review-main .customer-review .profile {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 14px;
}

.product-label .discription ul .rating-review-main .customer-review .profile .profile-img {
  width: 46px;
}

.profile-img-initial {
  width: 100%;
  height: 147px;
  border-radius: 50%;
  background: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: -webkit-xxx-large;
}

.product-label .discription ul .rating-review-main .customer-review .profile .profile-img .user-profile-img {
  width: 46px;
  height: 46px;
}

.product-label .discription ul .rating-review-main .customer-review .profile .profile-img img {
  width: 100%;
  height: 46px;
  border-radius: 50%;
}

.product-label .discription ul .rating-review-main .customer-review .profile .name {
  width: calc(100% - 46px);
}

.product-label .discription ul .rating-review-main .customer-review .profile .name span {
  font-size: 16px;
  font-weight: 500;
}

.product-label .discription ul .rating-review-main .customer-review .profile .name p {
  color: #828282;
  font-size: 12px;
  display: flex;
  margin: 6px 0 0;
}

.product-label .discription ul .rating-review-main .customer-review .profile .name p span {
  font-weight: 500;
  font-size: 12px;
}

.product-label .discription ul .rating-review-main .load-more-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 25px 0 0;
}

.product-label .discription ul .rating-review-main .load-more-btn button {
  width: 180px;
  height: 50px;
  background-color: #4f4f4f;
  color: #fff;
  font-weight: 600;
  line-height: 18px;
  font-size: 16px;
}

/* product page main css start */
#product-page-main {
  width: 100%;
  display: inline-block;
  background-color: #f2f2f2;
  padding: 70px 0 109px;
}

.productpage-main-slider {
  display: none;
}

.loader-container {
  position: absolute;
}

.product-col-main {
  width: 33.33%;
}

.filter-mix-main .mix-scartch-main .product-mix-list {
  margin: 0 0 30px;
  padding: 5px 15px 0;
  width: 100%;
}

.filter-mix-main .filter-main .filter .product-category-main {
  padding: 25px 20px 27px;
}

.filter-mix-main .filter-main .filter .product-category-main button,
.filter-mix-main .filter-main .filter .height-weight button {
  font-weight: 500;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
}

.filter-mix-main .filter-main .filter .product-category-main .input-field label {
  display: flex;
  gap: 9px;
}

.filter-mix-main .filter-main .filter .product-price-range input {
  height: 6px;
}

/* faq page css start */
.faq-main {
  width: 100%;
  display: inline-block;
  padding: 53px 0 60px;
  background-color: #f2f2f2;
}

.faq-head {
  width: 100%;
  display: inline-block;
  margin: 0 0 50px;
}

.faq-head ul {
  display: flex;
  align-items: center;
  gap: 14px;
}

.faq-head ul li {
  background-color: #fff;
  color: #828282;
  font-size: 16px;
  border: 1px solid #bdbdbd;
  padding: 10px 20px;
}

.faq-head ul li.active {
  background-color: #efc441;
  color: #fff;
  border: none;
}

.faq {
  width: 100%;
  display: inline-block;
}

.faq ul li {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 28px 41px;
  border: 1px solid #e0e0e0;
  margin: 0 0 10px;
}

.faq ul li h2 {
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
}

.faq ul li button {
  background: none;
  border: none;
}

.faq ul li.active .add-hide-btn button.hide-btn {
  display: unset;
}

.faq ul li .add-hide-btn button.hide-btn {
  display: none;
}

.faq ul li.active .add-hide-btn button.show-btn {
  display: none;
}

.faq ul li .faq-detail {
  width: 100%;
  display: none;
}

.faq ul li.active .faq-detail {
  display: inline-block;
}

.faq ul li .faq-detail p {
  color: #4f4f4f;
  line-height: 24px;
  margin: 12px 0 0;
}

.next-previous-slide {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 52px 0;
}

.next-previous-slide .slides-heading span {
  font-size: 14px;
  color: #000;
}

.next-previous-slide .slides-button {
  width: max-content;
}

.next-previous-slide .slides-button ul li {
  display: inline-block;
  padding: 0;
  width: auto;
  background: none;
  border: none;
}

.next-previous-slide .slides-button ul li a {
  width: 28px;
  height: 28px;
  display: inline-block;
  color: #828282;
  border-radius: 10px;
  text-align: center;
  line-height: 28px;
}

.next-previous-slide .slides-button ul li a.active-slide {
  background-color: #333;
  color: #fff;
}

/* contact page css start */
#contact-main {
  width: 100%;
  display: inline-block;
  padding: 100px 0 120px;
  background-color: #f2f2f2;
}

.contact-details-col {
  width: 55%;
  float: left;
  padding: 0 15px;
}

.contct-details {
  width: 100%;
  max-width: none;
}

.contct-details .contact-head {
  width: 100%;
}

.contct-details .contact-head h2 {
  font-weight: 600;
  font-size: 34px;
}

.contct-details .contact-head p {
  line-height: 26px;
  font-size: 20px;
  color: #4f4f4f;
  font-weight: 300;
  max-width: 700px;
  margin: 11px 0 53px;
}

.contct-details .contact-social {
  width: 100%;
  display: inline-block;
}

.contct-details .contact-social ul {
  max-width: 483px;
}

.contct-details .contact-social ul li {
  display: flex;
  align-items: center;
  padding: 32px 0;
  border-bottom: 1px dashed #bdbdbd;
}

/* 
.contct-details .contact-social ul:last-child li {
    border-bottom: none;
} */

.contct-details .contact-social ul li span {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0 0;
}

.contct-details .contact-social ul li .social-left {
  width: calc(100% - 60px);
}

.contct-details .contact-social ul li .social-left label {
  width: 100%;
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.contct-details .contact-social ul li .social-left a,
.contct-details .contact-social ul li .social-left p {
  font-size: 20px;
  color: #333;
  line-height: 30px;
}

.contact-form-col {
  width: 45%;
  float: left;
  padding: 0 15px;
}

.contact-form {
  width: 100%;
}

.contact-form form {
  width: 100%;
  background-color: #fff;
  padding: 31px 40px 30px;
  border-bottom: 3px solid #efc441;
  border-radius: 10px;
}

.contact-form form h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 26px;
}

.contact-form form .input-group {
  width: 100%;
  margin: 0 0 20px;
}

.contact-form form .input-group input {
  width: 100%;
  height: 54px;
  border: 1px solid #f2f2f2;
  padding: 0 23px;
  border-radius: 8px;
}

.contact-form form .input-group input[type="submit"] {
  font-weight: 800;
  color: #fff;
  background-color: #efc441;
  cursor: pointer;
  font-size: 16px;
}

.contact-form form .input-group input::placeholder,
.contact-form form .input-group textarea {
  color: #828282;
  font-size: 16px;
}

.contact-form form .input-group textarea {
  width: 100%;
  padding: 11px 0 0 23px;
  height: 100px;
  border: 1px solid #f2f2f2;
}

/*************************** contact page css end  **************************/

/* cart page css start */
/*************************** cart page css start *************************/
#cart-main {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding: 46px 0 113px;
  background-color: #f2f2f2;
}

.cart-head-col {
  width: 100%;
  float: left;
  padding: 0 15px;
}

.cart-head {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.cart-head h2 {
  font-weight: bold;
  font-size: 24px;
  margin: 0 0 37px;
}

.cart-head div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
}

.cart-head a {
  color: #000;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0 0 37px;
}

.cart-item-col {
  width: 70%;
  float: left;
  padding: 0 15px;
}

.cart-item-main {
  width: 100%;
  border: 1px solid #f2f2f2;
  padding: 26px 0;
  border-radius: 10px;
  background-color: #fff;
}

.ingrediants-main {
  margin: 20px 0 10px;
  display: inline-block;
}

.cart-item-main .cart-itme-head {
  width: 100%;
  display: inline-block;
  padding: 0 31px;
  margin: 0 0 12px;
}

.cart-item-main .cart-itme-head span {
  color: #828282;
  font-size: 14px;
  font-weight: 500;
}

.ingrediants-main .cart-item .cart-details {
  width: 100% !important;
}

.cart-item-main .cart-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  padding: 15px 31px 26px;
  margin: 5px 0 0;
  gap: 22px;
}

.cart-item-main .cart-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.cart-item-main .cart-item .cart-img {
  width: 160px;
  /* border: 1px solid #e0e0e0; */
}

.cart-item-main .cart-item .cart-img img {
  width: 100%;
  height: 110px;
  object-fit: cover;
  vertical-align: top;
  /* border-radius: 8px; */
  /* border-radius: 10px; */
}

.wishlist-main .cart-item .cart-img img {
  border-radius: 8px;
}

.cart-item-main .cart-item .cart-details {
  width: calc(100% - 160px);
}

.my-order-main .cart-item .cart-details {
  width: 100%;
}

.cart-item-main .cart-item .cart-details .cart-details-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-item-main .cart-item .cart-details .cart-details-head h3 {
  font-size: 18px;
  /* margin: 0 0 5px; */
  line-height: 18px;
}

.cart-item-main .cart-item .cart-details .cart-details-head h3 span {
  font-size: 18px;
  line-height: 18px;
  color: #828282;
  font-weight: normal;
}

.cart-item-main .cart-item .cart-details .cart-details-head span {
  font-size: 14px;
  line-height: 18px;
  color: #34d2ff;
  cursor: pointer;
}

.cart-item-main .cart-item .cart-details p {
  font-size: 14px;
  color: #828282;
  line-height: 20px;
  margin: 11px 0 26px;
}

.cart-item-main .cart-item .cart-details .cart-quantity {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-item-main .cart-item .cart-details .cart-quantity .quantity-btn {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
}

.cart-item-main .cart-item .cart-details .cart-quantity .quantity-btn button {
  width: 31px;
  height: 31px;
  background: none;
  border: 1px solid #e0e0e0;
  border-radius: unset !important;
}

.cart-item-main .cart-item .cart-details .cart-quantity .quantity-btn button i {
  color: #828282;
  font-size: 10px;
}

.cart-item-main .cart-item .cart-details .cart-quantity .quantity-btn span {
  font-weight: 600;
  color: #333;
  font-size: 14px;
  margin: 0 0 0;
  line-height: 18px;
}

.wishlist-main .cart-item .cart-details .cart-quantity .quantity-btn span {
  margin: 0;
}

.ingrediants-main .cart-item .cart-details .cart-quantity .quantity-btn span {
  margin: 0;
}

.cart-item-main .cart-item .cart-details .cart-quantity .cart-item-remove {
  width: max-content;
  display: flex;
}

.cart-item-main .cart-item .cart-details .cart-quantity .cart-item-remove button {
  color: #828282;
  line-height: 18px;
  background: none;
  border: none;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cart-item-main .cart-item .cart-details .cart-quantity .cart-item-remove button img {
  margin: 0 6px 0 0;
}

.days-title {
  color: #828282;
  line-height: 18px;
  background: none;
  border: none;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* margin-top: 8px; */
  margin-left: 12px;
}

.add-cart-item {
  width: 100%;
  display: flex;
  justify-content: right;
  margin: 21px 0 0;
}

.add-cart-item .add-item {
  width: 196px;
  height: 44px;
  background: none;
  border: 2px dotted #219653;
  color: #219653;
  cursor: pointer;
  line-height: 18px;
}

.add-cart-item .add-item i {
  margin: 0 6px 0 0;
}

.cart-checkout-col {
  width: 30%;
  float: left;
  padding: 0 15px;
}

.cart-checkout {
  width: 100%;
  background-color: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
}

.cart-checkout .checkout-head {
  width: 100%;
  display: inline-block;
  padding: 21px 30px;
  border-bottom: 1px solid #f2f2f2;
}

.cart-checkout .checkout-head span {
  font-weight: 500;
  font-size: 14px;
  color: #828282;
}

.cart-checkout .checkout-field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #4f4f4f;
  padding: 16px 30px;
  line-height: 16px;
}

.cart-checkout .checkout-field label,
.cart-checkout .checkout-field span {
  font-size: 16px;
  line-height: 16px;
  color: #4f4f4f;
}

/* 
.cart-checkout .checkout-field span {
    font-weight: bold;
} */

.cart-checkout .checkout-total {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 39px;
  border-top: 2px dotted #f2f2f2;
}

.cart-checkout .checkout-total label {
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
}

.cart-checkout .checkout-total span {
  color: #4f4f4f;
  font-size: 18px;
  font-weight: 500;
  line-height: 16px;
}

.Checkout-btn {
  width: 100%;
  display: flex;
  justify-content: right;
  margin: 12px 0 0;
}

.Checkout-btn button {
  width: 203px;
  background-color: #efc441;
  height: 50px;
  border: none;
  color: #fff;
  font-weight: bold;
  line-height: 18px;
  cursor: pointer;
  font-size: 16px;
}

.checkout-shipping-main {
  padding: 0;
}

.checkout-shipping-main .cart-itme-head {
  width: 100%;
  padding: 0;
}

.checkout-shipping-main .cart-itme-head ul {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 48px;
  padding: 20px 0 20px 38px;
  border-bottom: 1px solid #e0e0e0;
}

.checkout-shipping-main .cart-itme-head ul li {
  font-weight: 500;
  font-size: 14px;
  color: #828282;
  display: flex;
  gap: 12px;
  align-items: center;
}

.checkout-shipping-main .cart-itme-head ul li.active {
  color: #333;
}

.checkout-shipping-main .cart-itme-head ul li.active span {
  background-color: #219653;
}

.checkout-shipping-main .cart-itme-head ul li span {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  background-color: #bdbdbd;
}

.checkout-shipping-main .checkout-shipping {
  width: 100%;
  padding: 21px 37px 0;
}

.checkout-shipping-main .checkout-shipping .address {
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 19px 19px 23px 30px;
  margin: 0 0 20px;
  cursor: pointer;
  border-radius: 8px;
}

.checkout-shipping-main .checkout-shipping .address.active {
  border: 1px solid #219653;
}

.checkout-shipping-main .checkout-shipping .address .head {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.checkout-shipping-main .checkout-shipping .address .head span {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 6px 0 8px;
}

.checkout-shipping-main .checkout-shipping .address .head .address-manage .delete-btn {
  background-color: transparent;
  border: none;
}

/* .checkout-shipping-main .checkout-shipping .address.active input {
    
} */
.checkout-shipping-main .checkout-shipping .address .head input {
  width: 20px;
  height: 20px;
  accent-color: #219653;
}

.checkout-shipping-main .checkout-shipping .address .head span button {
  background: none;
  border: none;
}

.checkout-shipping-main .checkout-shipping .address h4 {
  font-size: 16px;
  font-weight: normal;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #333;
  margin: 6px 0 8px;
}

.rating-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-shipping-main .checkout-shipping .address h4 .circle {
  width: 4px;
  height: 4px;
  display: inline-block;
  background-color: #828282;
}

.checkout-shipping-main .checkout-shipping .address h4 span {
  font-size: 14px;
}

.checkout-shipping-main .checkout-shipping .address p {
  color: #828282;
  font-size: 14px;
  line-height: 20px;
}

.checkout-shipping-main .checkout-shipping .add-address-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0 29px;
}

.checkout-shipping-main .checkout-shipping .add-address-btn button {
  font-size: 14px;
  color: #EFC441;
  display: flex;
  gap: 9px;
  width: 335px;
  height: 44px;
  background: none;
  border: 2px dashed #EFC441;
  align-items: center;
  justify-content: center;
}

.checkout-shipping-main .checkout-shipping .check-billing-box {
  width: 100%;
}

.checkout-shipping-main .checkout-shipping .check-billing-box .field {
  width: auto;
  display: flex;
  gap: 9px;
  margin: 0 0 23px;
}

.checkout-shipping-main .checkout-shipping .check-billing-box .field input {
  width: 18px;
  height: 18px;
  accent-color: #219653;
}

.checkout-shipping-main .checkout-shipping .check-billing-box .field label {
  color: #4f4f4f;
  font-size: 16px;
}

.checkout-shipping-main .checkout-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 37px;
  border-top: 1px solid #e0e0e0;
  margin: 120px 0 0;
}

.checkout-shipping-main .checkout-btn button {
  width: 135px;
  height: 40px;
  background-color: #e0e0e0;
  color: #828282;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  border: none;
}

.checkout-shipping-main .checkout-btn button.save-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background-color: #efc441;
  color: #fff;
}

.checkout-shipping-main .cart-itme-head {
  border: none;
}

.checkout-order-summary .order-head {
  padding: 0 37px;
}

.checkout-order-summary .cart-item {
  border-bottom: 1px solid #e0e0e0;
}

.checkout-order-summary .cart-item-main {
  padding: 0;
  border: none;
}

.checkout-order-summary .checkout-btn {
  margin: 20px 0 0;
}

/*************************** cart page css end *************************/

/* profile setting css start */
#profile-main {
  width: 100%;
  display: inline-block;
  padding: 46px 0 204px;
  background-color: #f2f2f2;
}

.profile-head {
  width: 100%;
}

.profile-head h2 {
  font-weight: 600;
  font-size: 24px;
  margin: 0 0 49px;
}

.setting-detail {
  width: 100%;
  display: flex;
  gap: 21px;
}

.setting-detail .setting-option {
  width: 334px;
}

.setting-detail .setting-option ul {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.setting-detail .setting-option ul li {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 15px 24px;
  width: 100%;
  gap: 10px;
  color: #828282;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
}

.setting-detail .setting-option ul li .active-icon {
  display: none;
}

.setting-detail .setting-option ul li.active .active-icon {
  display: unset;
}

.setting-detail .setting-option ul li.active {
  border: 1px solid #efc441;
  color: #efc441;
}

.setting-detail .setting-option ul li.active img {
  display: none;
}

.setting-main {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: right;
}

.setting-detail .setting-option ul li .setting-menu .notification .notification-btn {
  width: auto;
  height: 28px;
}

.setting-detail .setting-option ul li .setting-menu .notification .notification-btn .switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
}

.setting-detail .setting-option ul li .setting-menu .notification .notification-btn .switch input {
  display: none;
}

.setting-detail .setting-option ul li .setting-menu .notification .notification-btn .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bdbdbd;
  transition: 0.4s;
  border-radius: 30px;
}

.setting-detail .setting-option ul li .setting-menu .notification .notification-btn .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  border-radius: 50%;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.setting-detail .setting-option ul li .setting-menu .notification .notification-btn input:checked+.slider {
  background-color: #219653;
}

.setting-detail .setting-option ul li .setting-menu .notification .notification-btn input:checked+.slider:before {
  transform: translateX(26px);
  /* Move button to the right when on */
  background-color: white;
  /* Button color when on */
}

.setting-detail .setting-view-main {
  width: calc(100% - 334px);
  background-color: #fff;
  border-radius: 10px;
}

.setting-detail .setting-view {
  width: 100%;
  display: none;
}

.setting-detail .setting-view.active {
  display: inline-block;
}

.setting-detail .setting-view .profile {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 33px;
  padding: 41px 0 49px 64px;
}

.setting-detail .setting-view .profile .profile-img {
  width: 160px;
  position: relative;
}

.setting-detail .setting-view .profile .profile-img img {
  width: 100%;
  height: 147px;
  border-radius: 50%;
  object-fit: cover;
}

.setting-detail .setting-view .profile .upload-img {
  width: calc(100% - 160px);
  display: flex;
  gap: 12px;
}

.setting-detail .setting-view .profile .upload-img .img-load {
  position: relative;
  width: 146px;
}

.setting-detail .setting-view .profile .upload-img .img-load input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.setting-detail .setting-view .profile .upload-img .img-load button {
  width: 100%;
  height: 37px;
  background: none;
  border: 1px solid #bdbdbd;
  color: #4f4f4f;
  font-size: 14px;
}

.setting-detail .setting-view .profile .upload-img button {
  width: 62px;
  height: 37px;
  background-color: #eb5757;
  border: none;
  color: #fff;
  font-size: 14px;
}

.setting-detail .information {
  width: 100%;
  padding: 0 64px 49px 64px;
}

.setting-detail .information h3,
.setting-view .change-password h3 {
  font-weight: 500;
  font-size: 20px;
  margin: 0 0 36px;
}

.setting-detail .information .input-group,
.setting-view .change-password .input-group {
  width: 100%;
  display: flex;
  gap: 42px;
  margin: 0 0 20px;
}

.setting-detail .information .input-group .input-field {
  width: 48%;
}

.setting-detail .information .input-group .input-field .height {
  width: 100%;
  display: flex;
  gap: 20px;
}

.setting-detail .information .input-group .input-field .height .input-field .height-devide {
  width: 100%;
  display: flex;
  gap: 10px;
}

.setting-detail .information .input-group .input-field .height .input-field .height-devide .input {
  position: relative;
  width: 100%;
}

.setting-detail .information .input-group .input-field .height .input-field .height-devide .input span {
  font-size: 14px;
  color: #9a9a9a;
  font-weight: normal;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 65%;
}

.setting-detail .information .input-group .input-field .country-select {
  width: 100%;
  display: flex;
}

.setting-detail .add-address-main .card-details .input-field .country-select {
  display: flex;
  justify-content: center;
  align-items: center;
}

.setting-detail .add-address-main .card-details .input-field .country-select select {
  padding: 5px;
  width: 66px !important;
}

.setting-detail .add-address-main .card-details .input-field .form-control-new {
  width: 45px;
  padding: 4px;
  border-right: none;
}

.setting-detail .add-address-main .card-details .input-field .form-control-input {
  border-left: none;
}

.setting-detail .information .input-group .input-field .country-select select {
  width: 80px;
  padding: 0 0 0 0;
}

.register-active .login-field .input-field .country-select {
  width: 100%;
  display: flex;
}

.register-active .login-field .input-field .form-control-input {
  border-left: none;
}

.register-active .login-field .input-field .form-control-input-number {
  border-left: none;
  padding: 0px;
  border-radius: 0px 8px 8px 0px;
}

.register-active .login-field .input-field .form-control-new {
  width: 45px;
  padding: 4px;
  border-right: none;
}

.register-active .login-field .input-field .country-select select {
  width: 80px;
  padding: 0 0 0 0;
  border: 1px solid #e8e5e5;
}

.description-limit {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  max-height: 3em;
}

.setting-detail .information .input-group .input-field label,
.setting-view .change-password .input-group .input-field label {
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: #333;
  margin: 0 0 5px;
}

.setting-detail .information .input-group .input-field label.height-label {
  display: flex;
  justify-content: space-between;
}

.setting-detail .information .input-group .input-field label.height-label span {
  font-size: 12px;
  color: #9a9a9a;
  font-weight: normal;
}

.setting-detail .information .input-group .input-field input,
.setting-detail .information .input-group .input-field select,
.setting-view .change-password .input-group .input-field input {
  height: 50px;
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 0px 5px;
  border-radius: 8px;
}

.setting-view .change-password .input-group .input-field input {
  padding: 0 10px;
}

/* .setting-detail .information .input-group .input-field input {
  padding: 0 0 0 8px;
  border-radius: 8px;
} */
.setting-detail .information .input-group .input-field .form-control-input {
  border-left: none;
  border-radius: unset;
}

.setting-detail .information .input-group .input-field .form-control-new {
  width: 45px;
  padding: 4px;
  border-right: none;
  border-radius: unset;
}

.setting-detail .information .input-group .input-field input::placeholder {
  font-size: 14px;
  color: #333;
}

.setting-detail .setting-btn {
  width: 100%;
  display: flex;
  justify-content: right;
  border-top: 1px solid #e0e0e0;
  padding: 18px 64px 22px 0;
  gap: 10px;
}

.setting-detail .setting-btn button {
  background-color: #f2f2f2;
  color: #828282;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  border: none;
  padding: 10px 20px;
}

.setting-detail .setting-btn button.update-btn {
  background-color: #efc441;
  color: #fff;
  width: 100px;
}

.spinner_overLay {
  position: absolute;
  top: 40%;
  right: 48%;
}

.setting-view .change-password {
  width: 100%;
  padding: 40px 62px 174px 59px;
}

.setting-view .change-password .input-group {
  gap: 27px;
  flex-wrap: wrap;
}

.setting-view .change-password .input-group .input-field {
  width: 30.33%;
  position: relative;
}
.setting-view .change-password .input-group .input-field svg {
  position: absolute;
  right: 13px;
  top: 38px ;
  font-size: 20px;
}

/* wishlist css */
.wishlist-main {
  padding: 0px 49px 13px;
}

.wishlist-main .cart-itme-head {
  padding: 0;
  margin: 40px 0 0;
  display: flex;
  justify-content: space-between;
}

.wishlist-main .cart-itme-head .filter {
  color: #efc441;
  cursor: pointer;
}

.wishlist-main .cart-itme-head span {
  font-weight: 500;
  font-size: 20px;
  color: #333;
  margin: 0 0 14px;
  display: block;
}

.wishlist-main .cart-item {
  padding: 10px 10px 10px;
  cursor: pointer;
  border-radius: 10px;
}

.wishlist-main .cart-item:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 10%);
  padding: 10px;
}

/* my order css */
.my-order-main.active {
  display: none;
}

.my-order-main .cart-item .cart-details .cart-details-head span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.my-order-main .cart-item .cart-details .cart-quantity .cart-item-remove span {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333;
  display: flex;
  align-items: center;
  gap: 5px;
}

.my-order-main .cart-item .cart-details .cart-quantity .cart-item-remove span .circle-pending {
  width: 8px;
  height: 8px;
  background-color: var(--yellow);
  border-radius: 50%;
  margin: 5px;
}

.my-order-main .cart-item .cart-details .cart-quantity .cart-item-remove span .circle-success {
  width: 8px;
  height: 8px;
  background-color: green;
  border-radius: 50%;
  margin: 5px;
}

.my-order-main .cart-item .cart-details .cart-quantity .cart-item-remove span .circle-cancel {
  width: 8px;
  height: 8px;
  background-color: gray;
  border-radius: 50%;
  margin: 5px;
}

.my-order-main .cart-item .cart-details .cart-quantity .cart-item-remove span .circle-danger {
  width: 8px;
  height: 8px;
  background-color: #eb5757;
  border-radius: 50%;
  margin: 5px;

  /* background-color: #ffe8e8; */
}

.my-order-main .cart-item .cart-details p,
.my-order-details .cart-item .cart-details p {
  margin: 11px 0 8px;
}

.my-order-main .cart-item .cart-details span.quantity,
.my-order-details .cart-item .cart-details span.quantity {
  font-size: 12px;
  line-height: 18px;
  color: #333;
  display: block;
  margin: 8px 0 3px;
}

.my-order-main .cart-item .cart-details .cart-details-head h3 {
  cursor: pointer;
}

.my-order-main .cart-item .cart-details .cart-quantity .quantity-btn span,
.my-order-details .cart-item .cart-details .cart-quantity .quantity-btn span {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  margin: 0;
}

.my-order-details {
  width: 100%;
  padding: 20px 64px;
  display: none;
}

.my-order-details.active {
  display: inline-block;
}

.my-order-details .head {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 40px 0 42px;
  justify-content: space-between;
}

.my-order-details .head a {
  color: #333;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.my-order-details .head .reorder,
.my-order-details .cart-item .cart-details .cart-quantity .cart-item-remove button.rating {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  color: #34d2ff;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  font-family: "Rubik", sans-serif;
}

.my-order-details .cart-item {
  padding: 0 0 26px;
}

.my-order-details .cart-item .cart-details .cart-quantity .cart-item-remove button.rating {
  gap: 0;
}

.my-order-details .cart-item .cart-details .cart-quantity .cart-item-remove button.rating img {
  width: 16px;
}

.my-order-details .checkout-shipping-main .checkout-shipping {
  padding: 0;
}

.my-order-details .checkout-shipping-main .checkout-shipping .address {
  padding: 15px 0 15px;
  border: none;
  border-bottom: 1px solid #f2f2f2;
}

.my-order-details .checkout-shipping-main .checkout-shipping .address .head {
  padding: 0;
}

.my-order-details .order-track-main {
  width: 100%;
}

.my-order-details .order-track-main .track-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0;
}

.my-order-details .order-track-main .track-head1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 16px 0 34px; */
}

.my-order-details .order-track-main .track-head h5 {
  font-weight: 500;
  font-size: 16px;
  margin: 6px 0 8px;
}

.my-order-details .order-track-main .track-head1 h4 {
  font-weight: 400;
  font-size: 15px;
}

.my-order-details .order-track-main .track-head p {
  color: #4f4f4f;
  font-weight: normal;
  font-size: 14px;
}

.my-order-details .order-track-main .track-head p span {
  font-weight: 600;
}

.my-order-details .order-track-main .track-path {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.my-order-details .order-track-main .track-path::after {
  content: "";
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 93%;
  background-color: #219653;
  height: 4px;
}

.my-order-details .order-track-main .track-path li h5 {
  color: rgb(43, 42, 42);
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 13px;
}

.my-order-details .order-track-main .track-path li span {
  color: #828282;
  font-size: 14px;
  display: block;
  margin: 14px 0 0;
}

.my-order-details .order-track-main .track-path li.current-status {
  text-align: left;
}

.my-order-details .order-track-main .track-path li.last-status {
  text-align: right;
}

.my-order-details .order-track-main .track-path li.last-status .circle {
  margin-right: 13px;
}

.my-order-details .order-track-main .track-path li {
  width: max-content;
  text-align: center;
  position: relative;
}

.my-order-details .order-track-main .track-path .circle,
.my-order-details .order-track-main .track-path .current-circle {
  width: 30px;
  height: 30px;
  background-color: #219653;
  border-radius: 50%;
  margin: 0 auto;
}

.my-order-details .order-track-main .track-path .circle,
.my-order-details .order-track-main .track-path .current-circle-cancelled {
  width: 30px;
  height: 30px;
  background-color: #219653;
  border-radius: 50%;
  margin: 0 auto;
}

.my-order-details .order-track-main .track-path .current-circle {
  border: 15px solid #219653;
  margin: 0 0 0 5px;
}

.my-order-details .order-track-main .track-path .current-circle-cancelled {
  border: 15px solid red;
  margin: 0 0 0 5px;
}

.order-confirm-main {
  width: 450px;
  padding: 44px 55px 36px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 10px;
}

.order-confirm-main span.party-popup {
  display: block;
  margin: 0 0 19px;
}

.order-confirm-main h2 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0 0 4px;
}

.order-confirm-main p {
  color: #828282;
  font-size: 16px;
  line-height: 20px;
}

.order-confirm-main .track-path {
  width: 100%;
  display: inline-block;
  margin: 22px 0 0;
  padding: 19px 0 0;
  border-top: 1px solid #f2f2f2;
}

.order-confirm-main .track-path span {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #828282;
}

.order-confirm-main .track-path h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.order-confirm-main .back {
  width: 100%;
  margin: 34px 0 0;
}

.order-confirm-main .back button {
  width: 144px;
  height: 40px;
  border: 1px solid #efc441;
  color: #efc441;
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
}

.my-order-details .order-track-main .track-path::after {
  content: "";
  position: absolute;
  top: 40px;
  left: 25.5%;
  transform: translateX(-50%);
  width: 43%;
  background-color: #219653;
  height: 4px;
}

.my-order-details .order-track-main .track-path .circle,
.my-order-details .order-track-main .track-path .current-circle {
  width: 30px;
  height: 30px;
  background-color: #828282;
  border-radius: 50%;
  margin: 0 auto;
}

.my-order-details .order-track-main .track-path .circle,
.my-order-details .order-track-main .track-path .current-circle-cancelled {
  width: 30px;
  height: 30px;
  background-color: #adadad;
  border-radius: 50%;
  margin: 0 auto;
}

.my-order-details .order-track-main .track-path::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 72%;
  transform: translateX(-50%);
  width: 47%;
  background-color: #dbdada;
  height: 4px;
  z-index: 0;
}

.my-order-details .order-track-main .track-path.active::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 70%;
  transform: translateX(-50%);
  width: 47%;
  background-color: #219653;
  height: 4px;
  z-index: 0;
}

.my-order-details .order-place {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  padding: 18px 0 42px;
  margin: 27px 0 0;
}

.my-order-details .order-place span {
  color: #4f4f4f;
  font-size: 16px;
}

.my-order-details .order-place p {
  margin: 8px 0 0;
  font-size: 12px;
  color: #828282;
}

.my-order-details .order-place .order-summary-btn {
  width: 192px;
}

.my-order-details .order-place .order-summary-btn button {
  height: 40px;
  width: 100%;
  border-radius: 10px;
  background-color: #fffcf4;
  border: 1px solid #efc441;
  font-weight: 500;
  font-size: 12px;
  color: #efc441;
}

/* manage address */
.manage-address {
  width: 100%;
  padding: 0 64px 0;
}

.manage-address .checkout-shipping-main .checkout-shipping {
  padding: 0 0;
}

.manage-address .cart-itme-head {
  margin: 33px 0 0;
}

.manage-address .cart-itme-head span {
  margin: 0 0 25px;
}

.manage-address .checkout-shipping-main .checkout-shipping .address .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage-address .checkout-shipping-main .checkout-shipping .address .head .address-manage {
  display: flex;
  align-items: center;
  gap: 17px;
}

.manage-address .checkout-shipping-main .checkout-shipping .address {
  margin: 0 0 19px;
}

.manage-address .checkout-shipping-main .checkout-shipping .address .head .address-manage button {
  background: none;
  border: none;
}

/* help support */
.help-support {
  border: none;
}

.setting-detail .information .help-note {
  display: inline-block;
  width: 100%;
}

.setting-detail .information .help-note p {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  color: #4f4f4f;
  line-height: 19px;
  margin: 20px 0 0;
}

.new_loader {
  width: 100%;
  display: flex;
  justify-content: center !important;
  height: 200px;
  align-items: center !important;
}

/* .carousel.carousel-slider .control-arrow {
  display: none;
}
.carousel .carousel-status {
  display: none;
} */

/* Payment cart Style  */



.payment-form-overlay {
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100vw;
  background-color: rgb(0 0 0 / 85%);
  z-index: 999;
}

.payment-form-overlay .payment-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 999;
  width: 500px;
  /* height: 328px; */
}

.payment-form-overlay>.payment-container>.cross-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.paymentButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 12px;
}

.pay-btn {
  background-color: #efc441;
  color: #fff;
  border: none;
  width: 100%;
  height: 40px;
}

.flavor-main .flavor-head,
.flavor-main .flavor-btn {
  margin: 0 !important;
}

.flavor-main .flavor-head,
.flavor-main .flavor-btn .sweetner-btn {
  display: flex;
  flex-direction: row;
  gap: 11px;
}

/* .flavor-main .flavor-head, */
.flavor-main .flavor-btn .sweetner-btn .sweetback-btn {
  width: 121px;
  height: 40px;
  color: #828282;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  border: none;
  background-color: white;
  border: 1px solid #bdbdbd;
}

.flavor-main .flavor-select-main {
  width: 100%;
  display: flex;
  height: 430px;
  overflow-y: auto;
}

.flavor-main .flavor-select-main .flavor-select {
  width: 100%;
  /* border-right: 1px solid #E0E0E0; */
  padding: 40px 19px 100px 40px;
}

.flavor-main .flavor-select .flavor-category .field {
  justify-content: space-between;
  width: 100%;
}

/* Range Slider as a Single Thumb Slider */
.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
  background: #ffbf00;
}

.single-thumb .range-slider__range {
  border-radius: 6px;
  background: #ffbf00;
}

.flavor-main .flavor-select .flavor-category .field .input-checkbox {
  display: flex;
}

.flavor-main .flavor-select .flavor-category .field .mode-select {
  display: flex;
  gap: 10px;
}

.flavor-main .flavor-select .flavor-category .field .mode-select button {
  background-color: transparent;
  width: 56px;
  height: 27px;
  border: 1px solid #bdbdbd;
  border-radius: 7px;
  color: #bdbdbd;
  font-size: 14px;
}

.flavor-main .flavor-select .flavor-category .field .mode-select button.active {
  border: 1px solid #efc441;
  color: #efc441;
}

.order-confirm-main {
  width: 450px;
  padding: 44px 55px 36px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.order-confirm-main span.party-popup {
  display: block;
  margin: 0 0 19px;
}

.order-confirm-main h2 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0 0 4px;
}

.order-confirm-main p {
  color: #828282;
  font-size: 16px;
  line-height: 20px;
}

.order-confirm-main .track-path {
  width: 100%;
  display: inline-block;
  margin: 22px 0 0;
  padding: 19px 0 0;
  border-top: 1px solid #f2f2f2;
}

.order-confirm-main .track-path span {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #828282;
}

.order-confirm-main .track-path h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.order-confirm-main .back {
  width: 100%;
  margin: 34px 0 0;
}

.order-confirm-main .back button {
  width: 144px;
  height: 40px;
  border: 1px solid #efc441;
  color: #efc441;
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
}

.mix-product-img {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  color: #efc441;
  border: 2px solid #efc441;
}

.completedOrder {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: rgb(0 0 0 / 85%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 999;
  width: 100vw;
  height: 100%;
}

/* gender and male modal css  */
.logout-main-one {
  width: 500px;
  height: 324px;
  text-align: center;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 111;
  border-radius: 10px;
}

.logout-main-one .card-details h5 {
  font-size: 20px;
  margin-top: 10px;
  font-weight: 700;
  font-family: "Rubik", sans-serif;
}

.logout-main-one .card-details p {
  font-size: 16px;
  font-weight: 400;
  color: #828282;
  line-height: 18px;
  margin-top: 20px;
  font-family: "Rubik", sans-serif;
}

.logout-main-one .logout-btn {
  width: 100%;
}

.logout-main-one .logout-btn .yes-btn {
  background-color: #efc441;
  color: white;
  width: 100px;
  height: 36px;
  border: 0px;
  margin-left: 10px;
  border-radius: 8px;
}

.logout-main-one .logout-btn .no-btn {
  background-color: #e0e0e0;
  color: #828282;
  width: 100px;
  height: 36px;
  border: none;
  margin-right: 10px;
  border-radius: 8px;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background-color: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background-color: red;
  cursor: pointer;
}

.gender_male-input {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  transition: all 0.3s ease;
  /* Add transition property */
}

.gender_male-input .input-field select {
  width: 200px;
  height: 36px;
  border: 1px solid #e0e0e0;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.3s ease;
  /* Add transition property */
}

.gender_male-input div button {
  width: 156px;
  height: 36px;
  transition: all 0.3s ease;
  /* Add transition property */
}

.gender_male-input div .guest {
  background-color: #e0e0e0;
  color: #828282;
  font-size: 14px;
  font-weight: 500;
  border: none;
  margin-right: 10px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.gender_male-input div .login {
  background-color: #efc441;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border: none;
  margin-right: 10px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.modal-cancel-btn {
  width: 94px;
  height: 40px;
  background: #e0e0e0;
  font-size: 14px;
  font-weight: 600;
  border: none;
  color: #828282;
}

.availableCrossIcon {
  position: absolute;
  right: 13px;
  top: 6px;
  font-size: 26px;
  color: black;
  font-weight: 400;
  cursor: pointer;
  background-color: none;
  border: none;
}

.rating_review-main {
  padding: 20px 40px;
  width: 400px;
  text-align: center;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  z-index: 111;
  border-radius: 10px;
  font-family: "Rubik", sans-serif;
}


.rating_review-main.active {
  opacity: 1;
  visibility: visible;
  z-index: 111;
}

.rating_review-main .input-field {
  width: 100%;
  text-align: left;
  margin: 19px 0 0;
}

.rating_review-main .input-field .input {
  width: 100%;
  margin: 0 0 26px;
}

.rating_review-main .input-field .input label {
  font-size: 14px;
  color: #999999;
  display: block;
  margin: 0 0 7px;
}

.rating_review-main .input-field .input textarea {
  height: 109px;
  box-shadow: 0 0 4px #efc441;
  border: none;
  width: 100%;
  padding: 14px 12px 0;
  font-size: 16px;
  border-radius: 8px;
  font-family: "Rubik", sans-serif;
}

.rating_review-main .input-field .input textarea::placeholder {
  color: #9ca3af;
}

.rating_review-main .input-field .input .star-icons {
  display: flex;
  gap: 12px;
  justify-content:space-between;
  width: 100%;
}

.rating_review-main .input-field .input .star-icons span {
  width: 35px !important;
}

.rating_review-main .input-field .input .star-icons span img {
  width: 55px;
}

.rating_review-main .logout-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.rating_review-main .logout-btn button {
  width: 94px;
  height: 40px;
  background-color: #e0e0e0;
  color: #828282;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  border: none;
  margin: 0;
}

.rating_review-main .logout-btn button.yes-btn {
  background-color: #efc441;
  color: #fff;
}

/* add new address popup css */
.country-select {
  display: flex;
}

input.form-control-new {
  border-radius: unset !important;
  border-right: none !important;
  width: 45px !important;
  padding: 5px !important;
  text-align: center;
}

input.form-control-input {
  border-radius: 0 8px 8px 0px !important;
  border-left: none !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0 8px !important;
}

.css-1jqq78o-placeholder {
  font-size: 14px !important;
}

.css-128ynr5-control {
  border-radius: 10px 0 0 10px !important;
}

.show_more-less {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 20px;
}

.show_more-less .show_more-button {
  background: #f2c94c;
  color: #ffffff;
  width: 100px;
  height: 36px;
  border: none;
  margin-right: 10px;
  border-radius: 8px;
}

.show_more-less .show_less-button {
  background-color: #e0e0e0;
  color: #828282;
  width: 100px;
  height: 36px;
  border: none;
  margin-right: 10px;
  border-radius: 8px;
}

.nav-link.active {
  border-bottom: 2px solid #f2c94c;
  padding-bottom: 0;
}

.active-tab-border {
  position: absolute;
  bottom: 0;
  height: 2px;
  /* Set your desired border height */
  background-color: 2px solid #f2c94c;
  /* Set your desired border color */
  transition: left 0.3s ease, width 0.3s ease;
}

.overlay-five {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black color */
  z-index: 9;
  /* Ensure the overlay is behind the sidebar */
  display: none;
  /* Initially hide the overlay */
}

.overlay-five.active {
  display: block;
  /* Show the overlay when the sidebar is active */
}

/* .mobile-logo {
  display: none;
} */
.logout-btn_header {
  width: 100px;
  height: 36px;
  border: none;
  background: #e04646;
  color: white;
  font-size: 14px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  bottom: 20px;
}

.mobile-logo_button,
.logout-btn_header {
  display: none;
}

/* React popup */
.ReactModalPortal .modal-content {
  border-radius: 10px !important;
  width: 90% !important;
  height: 100% !important;
}

.range_slider {
  flex-wrap: wrap !important;
}

.range_slider .form-range {
  height: 6px !important;
}

.cart_item_table {
  height: 250px !important;
  overflow: auto !important;
}

/* .wishlist_page-button {
  border: none;
  background: none;
  border: 1px solid #f2c94c;
  padding: 10px;
  font-size: "16px";
  font-weight: 600;
} */
.wishlist_page-button {
  border: 1px solid grey;
  color: grey;
  background: none;
  margin: 2px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: "16px";
  font-weight: 600;
}

/* .active-page {
  border: 1px solid #f2c94c;
  color: #f2c94c;
} */
.picker-container {
  border: 1px solid #f3d377;
  border-radius: 10px;
  margin-bottom: 10px;
}

.picker-container .picker-column .picker-item {
  color: #f9e6ac !important;
}

.picker-container .picker-column .picker-item.picker-item-selected {
  color: #f7c32b !important;
  font-size: larger;
  font-weight: 500;
}

.modal-cancel-btn,
.modal-submit-btn {
  margin: 0 0 20px;
}

.give_review-button {
  background: none;
  display: flex;
  justify-content: center;
  color: #219653;
  border: none;
  font-size: 16px;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
}

.go-back_button {
  background: none;
  border: none;
  display: flex;
  justify-content: space-evenly;
  width: 80px;
  font-weight: 600;
}

.order-filter-main {
  width: 500px;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 111;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.order-filter-main .filter-head {
  width: 100%;
  display: flex;
  padding: 24px 24px 21px 19px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 7%);
}

.order-filter-main .filter-head h2 {
  font-weight: 500;
  font-size: 20px;
}

.order-filter-main .filter-head button.close-btn {
  background: none;
  border: none;
}

.order-filter-main .status {
  width: 100%;
  padding: 28px 19px 22px;
  border-bottom: 1px solid #f2f2f2;
}

.order-filter-main .status h3,
.order-filter-main .date-range h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 14px;
}

.order-filter-main .status .input-group {
  width: 100%;
}

.order-filter-main .status .input-group .input-field {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 0 10px;
}

.order-filter-main .status .input-group .input-field input {
  width: 18px;
  height: 18px;
  border: 2px solid #bdbdbd;
  accent-color: #efc441;
}

.order-filter-main .date-range {
  width: 100%;
  padding: 16px 21px 35px;
  border-bottom: 1px solid #f2f2f2;
}

.order-filter-main .date-range .input-date {
  width: 100%;
  margin: 0 0 15px;
}

.order-filter-main .date-range .input-date label {
  display: block;
  margin: 0 0 10px;
  font-weight: normal;
  font-size: 14px;
}

.order-filter-main .date-range .input-date input {
  width: 200px;
  height: 50px;
  border: 1px solid #e0e0e0;
  padding: 0 10px;
  color: #bdbdbd;
  font-size: 14px;
  border-radius: 8px;
}

.order-filter-main .filter-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
  border-top: 1px solid #e0e0e0;
  position: absolute;
  bottom: 0;
}

.order-filter-main .filter-btn button {
  background-color: #e0e0e0;
  width: 121px;
  height: 40px;
  color: #828282;
  font-weight: 600;
  font-size: 14px;
  border: none;
}

.order-filter-main .filter-btn button.apply-btn {
  background-color: #efc441;
  color: #fff;
}

/* registration form */
.picker-container .picker-highlight {
  background-color: #edebeb;
  z-index: -1;
}

.picker-container .picker-column .picker-item.picker-item-selected {
  color: #222 !important;
}

.picker-container {
  margin: 10px 0 0;
}

.percent-rating {
  display: flex;
  flex-direction: column;
}

.percent-rating>div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.percent-rating label {
  margin-right: 5px;
}

.percent-line {
  width: 150px;
  height: 8px;
  background-color: lightgray;
  display: inline-block;
  position: relative;
  border-radius: 5px;
}

.percent-line-after {
  height: 100%;
  border-radius: 5px;
  background-color: #efc441;
}

/* Input type Range webkit */

input[type=range] {
  /*removes default webkit styles*/
  -webkit-appearance: none;



}

input[type=range]::-moz-range-track {
  /* width: 150px;
	height: 5px; */
  background: #ccc;
  border: none;
  border-radius: 3px;
}

input[type=range]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #004d66;
}

input[type=range]::-moz-range-progress {
  background: #EFC441;
  border-radius: 10px;
  height: 7px;
}





.search-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 0 8px 8px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
  width: 255px;
  display: flex;
  gap: 12px;
}

.search-input {
  border: none;
  width: 80%;

}
/* .search-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
} */

.search-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.search-button i {
  font-size: 20px;
  color: #333;
}

.mobile-search-bar {
  width: 100%;
  margin: 15px  0 0;
  display: none;
  border: 1px solid #ccc;
  padding: 0 20px;
  height: 40px;
  align-items: center;
  border-radius: 20px;
  gap: 7px;
}

.contact-banner {
  text-align: right;
}
.leave-review-not {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}



.mixname-main {
  width: 500px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  visibility: visible;
}
 
.mixname-main .head {
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
  padding: 21px 40px;
}
 
.mixname-main .input-field {
  width: 100%;
  padding: 33px 40px;
}
.mixname-main .input-field .input-group {
  margin: 0 0 45px;
}
.mixname-main .input-field .input-group label{
  display: block;
  margin: 0 0 5px;
  font-weight: 500;
  font-size: 14px;
}
.mixname-main .input-field .input-group input {
  width: 100%;
  height: 45px;
  border: 1px solid #E0E0E0;
  padding: 0 15px;
  font-size: 14px;
}
 
.mixname-main .input-field .btn-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.mixname-main .input-field .btn-group button {
  width: 121px;
  height: 40px;
  background-color: #E0E0E0;
  color: #828282;
  border: none;
  font-size: 14px;
  font-weight: 600;
}
.mixname-main .input-field .btn-group button.submit-btn {
  background-color: #EFC441;
  color: #fff;
}





 /* home page new Design  */
 .product-sell-main {
  background-color: #b7d766 !important;
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
  position: relative;
  padding: 75px 0 !important;
  /* border-radius: 143px; */
}
.product-sell-main .shop-header {
    justify-content: right;
    margin: 0 0 35px;
}
.product-sell-main .shop-header a {
    border: none;
    width: auto !important;
    height: auto;
    color: #fff;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 1px;
}
.product-sell-main .shop-header a img {
  transform: rotate(270deg);
  filter: brightness(0) invert(1);
  width: 15px;
}
.product-sell-main .shop-header .scroll-bottom-btn {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    width: 122px;
    height: 122px;
    border-radius: 50% !important;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}
.product-sell-main .product {
    border-radius: 20px;
}
#top-sell-grab {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin: 190px 0;
}
#top-sell-grab h2 {
    font-weight: 600;
    font-size: 55px;
    color: #000;
}
#top-sell-grab h2 span {
    font-weight: lighter;
    color: #454545;
    display: block;
}
 
#journy-main {
    width: 100%;
    display: inline-block;
    margin: 50px 0 0;
}
 
.journy-head {
    width: 100%;
    text-align: center;
    max-width: 1120px;
    margin: 0 auto 120px;
    border: 1px solid #1390B8;
    border-radius: 13px;
    padding: 10px;
}
.journy-head h2 {
    color: #138BB4;
    font-size: 65px;
    font-weight: 600;
}
.journy-boxes {
    width: 100%;
    max-width: 1150px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 145px;
    margin: 0 auto;
}
 
.journy-boxes .box {
    width: 100%;
    margin: 0 auto;
    background-color: #1193BB;
    border-top-left-radius: 150px;
    border-bottom-left-radius: 150px;
    padding: 65px 230px 70px 110px;
    position: relative;
}
.journy-boxes .box-reverse {
    background-color: #B7D765;
    border-top-left-radius:unset;
    border-bottom-left-radius: unset;
    border-top-right-radius:150px;
    border-bottom-right-radius: 150px;
    padding: 65px  110px 70px 230px;
}
.journy-boxes .box-reverse span {
    left: -256px;
}
.journy-boxes .box-reverse h3 {
    color: #000 !important;
    font-size: 35px !important;
    text-align: right;
}
.journy-boxes .box-reverse p {
    color: #000 !important;
}
.journy-boxes .box-reverse .box-redirect a {
    background-color: #1193BB !important;
}
.journy-boxes .box-reverse .box-redirect a img {
    filter: brightness(0) invert(1);
}
.journy-boxes .box h3 {
    color: #fff;
    font-weight: 600;
    font-size: 36px;
    margin: 0 0 16px;
}
.journy-boxes .box .box-redirect {
    display: flex;
    gap: 20px;
}
.journy-boxes .box .box-redirect a {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
}
.journy-boxes .box .box-redirect p {
    color: #fff;
    font-size: 35px;
    width: calc(100% - 90px);
    font-weight: lighter;
    text-align: right;
}
.journy-boxes .box span {
    position: absolute;
    top: -76px;
    right: -256px;
    width: fit-content;
}
 
#new-banner-main {
    width: 100%;
    display: inline-block;
    background-color: #128EB7;
    height: 842px;
    border-bottom-left-radius: 100% 100%;
    border-bottom-right-radius: 100% 100%;
    text-align: center;
    overflow: hidden;
}
#new-banner-main .free-shipping-btn:hover {
    background-color: #fff;
    color: #333;
    cursor: pointer;
}
#new-banner-main .free-shipping-btn {
    display: block;
    width: 490px;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 28px;
    border-radius: 13px;
    border: 1px solid rgba(255, 255, 255, 50%);
    margin: 82px auto 105px;
}
 
#new-banner-main .banner-circle {
    width: 1018px;
    height: 1000px;
    margin: 0 auto;
    padding: 66px;
    border-radius: 50%;
    background-color: #2B9ABE;
    position: relative;
    bottom: -10px;
}
#new-banner-main .banner-circle .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color:#429CBE ;
    padding: 107px 0 0;
}
#new-banner-main .banner-circle .circle h2 {
    color: #fff;
    font-weight: bold;
    font-size: 74px;
}
#new-banner-main .banner-circle .circle button {
    width: 70px;
    height: 70px;
    background-color: #B7D766;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 17px auto 0;
}
#new-banner-main .banner-circle .circle button a img {
    filter: brightness(0) invert(1);
    transform: rotate(90deg);
    width: 27px;
}

 