@media screen and (max-width:3000px) {
    #new-banner-main {
        height: 420px;
    }
    .journy-head {
        max-width: 700px;
    }
    #new-banner-main .banner-circle {
        padding: 20px;
    }
    .journy-boxes .box-reverse .box-redirect p {
        text-align: right !important;
    }
    .journy-boxes {
        max-width: 1010px;
    }
    .journy-boxes .box {
        padding: 40px 210px 40px 110px;
    }
    #new-banner-main .free-shipping-btn {
        width: 300px;
        height: 45px;
        font-size: 16px;
        margin: 35px auto 25px;
    }
    #new-banner-main .banner-circle .circle {
        padding: 35px 0 0;
    }
    #new-banner-main .banner-circle .circle h2 {
        font-size: 45px;
    }
    #new-banner-main .banner-circle .circle a img {
        width: 290px;
    }
    #new-banner-main .banner-circle .circle button {
        width: 65px;
        height: 65px;
    }
    #new-banner-main .banner-circle .circle button a {
        height: 35px;
    }
    #new-banner-main .banner-circle .circle button a img {
        width: 22px;
    }
    .journy-boxes .box .box-redirect a {
        width: 55px;
        height: 55px;
    }
    .journy-boxes .box .box-redirect a img {
        width: 15px;
    }
    #top-sell-grab {
        margin: 155px 0 100px;
    }
    #top-sell-grab h2 {
        font-size: 40px;
    }
    .journy-boxes .box span {
        right: -85px;
        top: -100px;
    }
    .journy-boxes .box span img {
        width: 350px;
    }
    .journy-boxes .box-reverse span {
        left: -75px;
        top: -55px;
    }
    .journy-head h2  {
        font-size: 34px;
    }
    .header nav ul li {
        padding: 0 19px;
    }
    .journy-boxes .box h3 {
        font-size: 26px !important; 
    }
    .journy-boxes .box-reverse span img {
        width: 275px;
    }
    .journy-boxes .box .box-redirect p  {
        font-size: 28px;
        text-align: left;
    }
    .journy-boxes .box-reverse {
        padding: 40px 110px 40px 288px;
    }
    .product-sell-main .shop-header .scroll-bottom-btn {
        width: 80px;
        height: 80px;
    }
    .product-sell-main .shop-header .scroll-bottom-btn img {
        width: 45px;
    }
}
 
 

@media screen and (max-width: 1365px) {
    .product-page-hide {
        display: none;
    }

    .product-page-slider {
        width: 100%;
        display: inline-block;
    }

    .productpage-main-slider {
        width: 100%;
        display: inline-block;
        padding: 0 15px;
    }

    .product-col-main {
        width: 100%;
    }

    .slick-dots li button:before {
        font-size: 55px;
    }

    /* .filter-mix-main .mix-scartch-main .mix-scratch {
        background: transparent;
        cursor: pointer;
    } */

    .product-bio-main .product-bio span.price {
        padding: 0 0 20px;
        margin: 0 0 15px;
    }
    .header nav ul li {
        padding: 0 19px;
    }
}

@media screen and (max-width: 1279px) {
    .footer-logo-main a.number {
        font-size: 26px;
    }
    .header nav {
        width: 40%;
    }

    /* .product span,
    .product h3,
    .product .rating {
        padding: 0 15px;
    } */
    .product .detail {
        padding: 0 15px;
    }

    .product {
        height: 440px;
    }

    .product-detail {
        padding: 20px 15px;
    }

    .shop-category .shop-redirect span {
        font-size: 20px;
    }

    .selling-col {
        width: 50%;
    }

    .new-arrival-col {
        width: 50%;
    }

    .new-arrival {
        margin: 0 0 20px;
    }

    .new-arrival .arrival-img img {
        width: 370px;
        margin: 0 auto;
    }

    #product-selling-main h2 {
        margin: 0 0 20px;
    }

    .new-arrival-product .slick-prev,
    .new-arrival-product .slick-next {
        top: -45px;
    }

    .product-label .discription ul li {
        width: 20%;
    }

    .product-bio-main {
        gap: 40px;
    }

    .product-bio-main .product-bio p.category {
        margin: 20px 0 15px;
    }

    .product-bio-main .product-bio span.quantity-label {
        margin: 23px 0;
    }

    .create-mix-main .create-mix .create {
        min-height: 225px;
    }

    .setting-detail .information {
        padding: 0 30px 49px 30px;
    }

    .setting-detail .setting-btn {
        padding: 18px 30px 22px 0;
    }

    .setting-view .change-password .input-group .input-field {
        width: 47%;
    }

    .setting-view .change-password {
        padding: 34px 35px 174px 30px;
    }

    .cart-items-main {
        width: 992px;
    }

    .mix-ingrediants-range-main {
        height: 295px;
    }
}

@media screen and (max-width: 1099px) {
    .product-label .discription ul .all-reviews {
        gap: 18px;
    }
    .my-order-details .order-track-main .track-path::after {
        left: 27.5%;
        width: 37%;
    }
    .my-order-details .order-track-main .track-path::before {
        width: 47%;
    }
    .wishlist-main .cart-item:hover {
        box-shadow: none;
    }

    .product .product-img img {
        width: 100%;
        height: 200px;
    }

    .header nav ul li {
        display: inline-block;
        padding: 0 15px;
    }

    .product-main-slider {
        display: inline-block;
        padding: 0 15px;
    }

    

    .mix-product-found {
        display: unset;
    }

    .product-col {
        width: 33.33%;
    }

    .product-slider {
        width: 100%;
        display: inline-block;
        margin: 0 ;
    }

    .slick-dots li button:before {
        font-size: 15px;
    }

    .selling-product .best-product .product-label {
        padding: 17px 0 20px 32px;
    }

    .filter-mix-main {
        gap: 15px;
    }

    .productpage-main-slider .slick-prev,
    .slick-next {
        display: none !important;
    }

    .product-bio-main .product-bio span.quantity-label {
        margin: 20px 0;
    }

    .product-bio-main .product-bio span.rating {
        margin: 12px 0 20px;
    }

    .product-detail-col {
        width: 33.33%;
    }

    .product-detail-col .product {
        height: 430px;
    }

    .filter-mix-main {
        flex-wrap: wrap;
    }

    .filter-mix-main .filter-main {
        width: 50%;
    }

    .filter-mix-main .mix-scartch-main {
        width: 100%;
    }

    .cart-checkout .checkout-head {
        padding: 21px 16px;
    }

    .cart-checkout .checkout-field {
        padding: 16px 15px;
    }

    .setting-detail .information .input-group,
    .setting-view .change-password .input-group {
        gap: 20px;
    }

    .wishlist-main {
        padding: 0 20px 58px;
    }

    .cart-items-main {
        width: 768px;
    }
}

/* @media screen and (max-width: 991px) {
  .selling-col {
    width: 50%;
  }
  .mobile-logo_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logout-btn_header {
    display: unset;
  } */

@media screen and (max-width:991px) {
    #new-banner-main {
        height: 490px;
    }
    #new-banner-main .banner-circle {
        width: 650px;
        height: 650px;
    }
    #journy-main {
        margin: 60px 0 0;
    }
    .journy-head h2 {
        font-size: 32px;
    }
    .journy-boxes .box span img {
        width: 300px;
    }
    .journy-boxes .box {
        padding: 45px 20px 45px 48px;
    }
    .journy-boxes .box .box-redirect a  {
        width: 55px;
        height: 55px;
    }
    .journy-boxes .box .box-redirect a img {
        width: 20px;
    }
    .journy-boxes .box span {
        display: none;
    }
    #top-sell-grab {
        margin: 60px 0;
    }
    #top-sell-grab h2 {
        font-size: 32px;
    }
    .product-sell-main .shop-header {
        margin: 0 0 40px;
    }
    .product-sell-main .shop-header .scroll-bottom-btn {
        width: 55px;
        height: 55px;
    }
    .product-sell-main .shop-header .scroll-bottom-btn img {
        width: 30px;
    }
    .product-sell-main .shop-header a {
        font-size: 24px;
    }
    .product-sell-main {
        padding: 100px 0  35px !important;
    }
    .journy-head {
        margin: 0 auto 40px;
        padding: 20px;
    }
    .journy-boxes {
        row-gap: 40px;
    }
    .journy-boxes .box h3 {
        font-size: 26px;
    }
    .journy-boxes .box .box-redirect p {
        font-size: 22px;
    }
    .journy-boxes .box-reverse h3 {
        font-size: 26px !important;
        text-align: left  !important;
    }
    #new-banner-main .free-shipping-btn {
        width: 325px;
        height: 55px;
        font-size: 18px;
        margin: 30px auto;
    }
    #new-banner-main .banner-circle .circle h2 {
        font-size: 32px;
    }
    #new-banner-main .banner-circle .circle a img {
        width: 250px;
    }
    #new-banner-main .banner-circle .circle button {
        width: 55px;
        height: 55px;
    }
    #new-banner-main .banner-circle .circle button img {
        width: 16px;
    }
    .product-hide {
        display: unset;
    } 
    .product-hide .product-col {
        width: 50%;
    }
    #header-main {
        padding: 0 25px;
    }
    .header .user ul {
        gap: 23px;
    }
    .mobile-search-bar {
        display: flex;
    }
    .header .user ul .search_icon_mobile {
        display: unset;
        padding: 5px 0 0;
      }
    .header nav {
        width: auto;
        text-align: left;
    }
    .header .user ul li.bar-btn {
        display: unset;
      }
    .search-container {
        display: none;
    }
    .filter-mix-main .mix-filter-main .filter .product-category-main {
        width: 100% !important;
        flex-wrap:wrap;
        row-gap: 10px !important;
    }
    .filter-mix-main .mix-filter-main .filter .gender {
        width: max-content;
        padding: 22px 25px 2px;
        display: flex;
        gap: 25px;
    }
    .filter-mix-main .mix-filter-main .filter .gender button img {
        display: none;
    }
    .filter-mix-main .mix-filter-main .filter .height-weight button img {
        display: none;
    }
    .filter-mix-main .mix-filter-main {
        width: 100%;
    }
    .filter-mix-main .mix-filter-main .filter .gender .input-field {
        width: auto;
    }
    .filter-mix-main .mix-filter-main .filter .height-weight {
        padding: 16px 25px 25px;
    }
    .filter-mix-main .mix-filter-main .filter .product-price-range {
        padding: 16px 25px 10px;
    }
    .product-label .discription ul .rating-review-main .rating-review .product-rating {
        text-align:left;
    }
    .product-label .discription ul .all-reviews .customer-review {
        width: 48% !important;
    }
    .product-label .discription ul .all-reviews {
        justify-content: space-between;
    }
    
    .mix-ingrediants-range-main .mix-ingrediants-range {
        height: 28vh;
        overflow-x: hidden;
    } 
    .addmix-ingredients-main .addmix-ingredients-head {
        margin: 0 0 10px;
    }
    .productpage-main-slider {
        padding: 0;
    }
    .product-col-main {
        width: 50% !important;
    }
    .my-order-details .order-track-main .track-path::after {
        left: 26.5%;
        width: 42%;
    }
    .total-value {
        margin: 140px 0 0 !important;
    }
    .create-mix-main .create-mix .create .creta-head input::after {
        top: -1px;
        right: -2px;
        width: 29px;
        height: 30px;
    }
    .product-label .discription ul .rating-review-main .rating-review .product-rating span {
        justify-content: center;
        font-size: 16px;
    }

    .selling-col {
        width: 50%;
    }

    .header .user ul li.bar-btn {
        padding: 5px 10px 0;
    }

    .mobile-logo_button {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logout-btn_header {
        display: unset;
    }

    .product-label {
        margin: 55px 0 0;
    }

    .product-bio-main .product-bio .quantity button.cart-btn,
    .product-label .discription ul .rating-review-main .load-more-btn button {
        height: 40px;
    }

    .product-label ul {
        justify-content: space-between;
        margin: 0 0 20px;
    }

    .product-label .discription ul .rating-review-main .rating-review {
        margin: 0 0 20px;
    }

    .product-label .discription ul .rating-review-main .customer-review {
        padding: 15px;
    }

    .create-mix-main .create-mix .create .creta-head input {
        width: 25px;
        height: 25px;
    }

    .create-mix-main .create-mix .create .creta-head img {
        width: 40px;
    }

    .create-mix-main .create-mix .create {
        min-height: 210px;
    }

    .flavor-main .flavor-select-main .flavor-select {
        padding: 20px 19px 100px 35px;
    }

    .Checkout-btn button {
        height: 40px;
    }

    .cart-head h2 {
        margin: 0 0 20px;
    }

    .help-support .cart-itme-head span {
        margin: 0;
    }

    #profile-main {
        padding: 45px 0 80px;
    }

    .setting-detail .setting-view .profile {
        gap: 10px;
        padding: 30px 0 49px 25px;
    }

    .profile-head h2 {
        margin: 0 0 20px;
    }

    .selling-col {
        width: 50%;
    }

    .header .user ul li.bar-btn {
        padding: 5px 10px 0;
    }

    /* .product {
        height: max-content;
    } */
    .product-main-slider .slick-slider .slick-track {
        width: 100% !important;
    }

    .product-col {
        width: 100%;
    }

    .product-slider {
        margin: 0 -15px;
    }

    .product-main-slider {
        padding: 0 15px 0 45px;
    }

    .header nav ul {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: -100%;
    }

    .header nav ul.menu-active {
        opacity: 1;
        visibility: visible;
        z-index: 111;
        top: 0;
        left: 0;
        height: 100vh;
        overflow-y: auto;
        width: 270px;
        padding: 25px 20px;
        background-color: #fff;
        transition: all 0.5s;
    }

    .header nav ul.menu-active button.close-btn {
        display: flex;
        justify-content: right;
        width: 100%;
        padding: 20px 0 0;
        /* max-width: max-content; */
    }

    .header nav ul.menu-active li {
        display: block;
        padding: 15px 0;
    }

    .header nav ul.menu-active input {
        display: block;
        margin: 10px 0 0;
        width: 100%;
    }

    .header .user ul li.search-btn {
        display: none;
    }

    .header .user ul li.bar-btn button {
        display: block;
    }

    .overlay.menu-active {
        opacity: 1;
        visibility: visible;
    }

    .footer-logo-col,
    .footer-col {
        width: 50%;
        margin: 0 0 30px;
    }

    .footer-middle {
        padding: 48px 0 15px;
    }

    .footer.pl {
        padding: 0;
        margin: 0;
    }

    .footer-logo-main .social-link {
        margin: 30px 0 0;
    }

    .footer-top.ml {
        padding: 0;
    }

    .footer-bottom span {
        margin: 0 0 0 15px;
    }

    .customer-slider-main .slick-prev,
    .customer-slider-main .slick-next {
        width: 40px;
        height: 40px;
    }

    .customer-slider-main .slick-prev,
    .customer-slider-main .slick-next {
        top: -30px;
    }

    .customer-details .customer-profile {
        margin: 20px 0 0;
    }

    .customer-details {
        height: max-content;
        padding: 20px;
    }

    .customer-details p {
        min-height: auto;
    }

    .customers-details-main {
        top: 40px;
    }

    .customer-bg-img img {
        height: 500px;
    }

    #product-main {
        padding: 60px 0;
    }

    .shop-mobile {
        display: inline-block;
        padding: 0 30px;
    }

    .shop-web {
        display: none;
    }

    .shop-category-col {
        width: 100%;
    }

    .shop-slider {
        width: 100%;
        display: inline-block;
    }

    .product-bio-main {
        flex-wrap: wrap;
    }

    .product-bio-main .product-profile {
        width: 100%;
    }

    .product-bio-main .product-bio {
        width: 100%;
    }

    .product-label .discription ul li {
        width: 25%;
    }

    .product-label .discription ul .rating-review-main .rating-review .product-rating {
        width: 24%;
    }

    .product-label .discription ul .rating-review-main .rating-review .percent-rating {
        width: 38%;
    }

    .product-label .discription ul .rating-review-main .rating-review {
        flex-wrap: wrap;
        gap: 5px;
    }

    .product-label .discription ul .rating-review-main .rating-review .product-rating {
        width: 100%;
    }

    .product-label .discription ul .rating-review-main .rating-review .product-rating {
        margin: 0 0 25px;
    }

    .product-label .discription ul .rating-review-main .rating-review .percent-rating {
        width: 50%;
    }

    .product-detail-col {
        width: 50%;
    }

    .product-detail-col .product {
        height: auto;
    }

    .cart-item-col {
        width: 100%;
    }

    .cart-checkout-col {
        width: 50%;
        float: right;
    }

    .cart-checkout {
        margin: 20px 0 0;
    }

    .setting-detail {
        flex-wrap: wrap;
    }

    .setting-detail .setting-option {
        width: 100%;
    }

    .setting-detail .setting-view-main {
        width: 100%;
    }

    .wishlist-main {
        padding: 0 20px 58px;
    }

    .cart-item-main.my-order-details {
        padding: 0 20px;
    }

    .my-order-details .head {
        padding: 20px 0 10px;
    }

    .flavor-main,
    .add-sweetners-main,
    .addmix-ingredients-main {
        width: 600px;
    }
}

@media screen and (max-width: 850px) {
    .my-order-details .order-track-main .track-path::before {
        left: 71%;
    }
    .modal-overlay_one {
        width: 90% !important;
    }
    .selling-col {
        width: 100%;
    }

    .new-arrival-col {
        width: 100%;
    }

    .filter-mix-main {
        flex-wrap: wrap;
    }

    .filter-mix-main .filter-main {
        width: 50%;
    }

    .filter-mix-main .mix-scartch-main {
        width: 100%;
    }

    .contact-details-col {
        width: 100%;
    }

    .contact-form-col {
        width: 100%;
    }

    .contct-details .contact-head p {
        margin: 11px 0 0;
    }

    .contct-details .contact-social ul {
        max-width: none;
    }

    .cart-items-main {
        width: 640px;
    }
}

@media screen and (max-width: 767px) {
    .footer-top-row {
        flex-wrap: wrap;
    }
    .my-order-details .order-track-main .track-path::after {
        left: 25.5%;
        width: 41%;
    }
    .my-order-details .order-track-main .track-path::before {
        width: 45%;
    }
    .filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scratch-right {
        justify-content: left;
    }

    .footer-col {
        width: 50%;
    }

    .footer-top-col {
        width: 100%;
        border-bottom: 1px solid #e0e0e0;
    }

    .footer-top {
        margin: 20px 0;
    }

    .footer-top-col .footer-top:last-child {
        border: none;
    }

    .footer-logo-col,
    .footer-col {
        width: 100%;
        border-bottom: 1px solid #e0e0e0;
    }

    .footer-middle {
        padding: 0 0;
        border: none;
    }

    .footer {
        padding: 20px 0;
    }

    .footer-logo-main {
        padding: 0 0 20px;
    }

    .footer-top-main {
        margin: 0 0 40px;
    }

    .footer.pl {
        padding: 20px 0;
        margin: 0;
    }

    .customer-head h2,
    .shop-header h2 {
        font-size: 30px;
    }

    #product-main {
        padding: 40px 0;
    }

    .create-mix-main .create-mix {
        flex-wrap: wrap;
        gap: 20px;
    }

    .create-mix-main .create-mix .create {
        width: 100%;
        padding: 15px;
        min-height: max-content;
    }

    .filter-mix-main .mix-scartch-main .mix-scartch-head {
        flex-wrap: wrap;
    }

    .filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scartch-left {
        width: 100%;
        margin: 0 0 20px;
    }

    .filter-mix-main .filter-main {
        width: 100%;
    }

    .cart-checkout-col {
        width: 100%;
    }

    .cart-item-main .cart-item .cart-img img {
        height: 150px;
    }

    .checkout-shipping-main .cart-itme-head ul {
        flex-wrap: wrap;
        gap: 25px;
    }

    .checkout-shipping-main .cart-itme-head ul li {
        width: 100%;
    }

    .flavor-main,
    .add-sweetners-main,
    .addmix-ingredients-main {
        width: 450px;
    }

    .flavor-main .flavor-select .flavor-category .field,
    .add-sweetners-main .flavor-select .flavor-category .field,
    .addmix-ingredients-main .flavor-select .flavor-category .field {
        width: 100%;
        justify-content: space-between;
        gap: 20px;
    }

    .flavor-main .flavor-btn,
    .add-sweetners-main .flavor-btn,
    .addmix-ingredients-main .flavor-btn {
        padding: 15px;
        flex-wrap: wrap;
    }

    .flavor-main .flavor-btn button,
    .add-sweetners-main .flavor-btn button,
    .addmix-ingredients-main .flavor-btn button {
        width: 110px;
    }

    .cart-items-main {
        width: 450px;
        padding: 15px;
    }

    .cart-items-main .cart-item-button button {
        width: 130px;
        height: 40px;
    }

    .footer-logo-col,
    .footer-col {
        margin: 0;
    }
}

@media screen and (max-width: 639px) {
    .selling-col {
        width: 100%;
    }

    .new-arrival-col {
        width: 100%;
    }

    .footer-top-col {
        width: 100%;
    }

    .product .product-img img {
        width: 100%;
        height: 270px;
        margin: 0 auto;
    }

    .banner-text .banner h1 {
        font-size: 40px;
    }

    .product-label .discription ul .rating-review-main .rating-review .percent-rating {
        width: 100%;
    }

    .product-label .discription ul li {
        width: 50%;
    }

    .product-label ul {
        gap: 30px;
        margin: 0 0 25px;
    }

    .product-label ul li {
        font-size: 18px;
        padding: 0 0 10px;
    }

    .product-label {
        margin: 45px 0 0;
    }

    .product-label .discription ul .rating-review-main .customer-review {
        padding: 15px;
    }

    .product-detail-col {
        width: 100%;
    }

    .faq-head ul li {
        font-size: 15px;
    }

    .faq-head ul {
        flex-wrap: wrap;
    }

    .my-order-main .cart-item .cart-details .cart-details-head h3 {
        margin: 0;
    }

    .cart-item-main .cart-item .cart-details .cart-details-head {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 575px) {
    #new-banner-main {
        height: 390px;
    }
    #new-banner-main .banner-circle {
        width: 350px;
        height: 350px;
        padding: 15px;
    }
    #new-banner-main .banner-circle .circle {
        padding: 50px 0 0;
    }
    #new-banner-main .banner-circle .circle a img {
        width: 180px;
    }
    #new-banner-main .free-shipping-btn {
        width: 275px;
        height: 45px;
        font-size: 16px;
        margin: 25px auto;
    }
    .journy-head h2 {
        font-size: 18px;
    }
    .journy-head {
        padding: 15px;
    }
    #journy-main {
        margin: 45px 0 0;
    }
    .journy-boxes .box h3 {
        font-size: 18px !important;
        margin: 0 0 7px;
    }
    .journy-boxes .box .box-redirect p {
        font-size: 14px;
    }
    .journy-boxes .box .box-redirect a {
        width: 40px;
        height: 40px;
    }
    .journy-boxes .box .box-redirect a img {
        width: 10px;
    }
    .journy-boxes .box {
        padding: 25px 20px 25px 48px;
    }
    .journy-boxes .box-reverse h3 {
        font-size: 18px !important;
    }
    #top-sell-grab h2 {
        font-size: 19px;
        text-align: left;
    }
    .product-sell-main {
        padding: 15px 0  30px !important;
        border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    }
    #new-banner-main .banner-circle .circle button a> img {
        width: 18px;
    }
    .product-sell-main .shop-header .scroll-bottom-btn {
        width: 50px;
        height: 50px;
        top: 25px;
    }
    .product-sell-main .shop-header {
        margin: 0 0 15px;
        padding: 0 10px;
    }
    #top-sell-grab {
        margin: 45px 0;
    }
    .journy-boxes .box-reverse {
        padding: 15px 20px 15px 15px;
    }
    .mix-scratch-table {
        background-color: #fff !important;
    }
    .header .user ul li {
        padding: 0 10px;
    }

    .header .logo a img {
        width: 100px;
    }

    .footer-bottom {
        flex-wrap: wrap;
        padding: 20px 0;
    }

    .footer-bottom span {
        margin: 0 10px 0 0;
    }

    .footer-bottom p {
        margin: 0 0 10px;
    }

    .footer-logo-main .footer-logo img {
        width: 130px;
        height: 40px;
    }

    .footer-logo-main a.number {
        font-size: 18px;
        margin: 10px 0;
    }

    .footer-logo-main a {
        font-size: 16px;
    }

    .footer-logo-main a img {
        width: 20px;
    }

    .footer h2 {
        margin: 0 0 5px;
    }

    .customer-head h2,
    .create-mix-main h2,
    .contct-details .contact-head h2 {
        font-size: 22px;
    }

    .create-mix-main h2 {
        margin: 0 0 15px;
    }

    .customer-bg-img img {
        height: 600px;
    }

    .customer-slider-main .slick-prev,
    .customer-slider-main .slick-next {
        width: 35px;
        height: 35px;
    }

    .shop-header {
        flex-wrap: wrap;
        margin: 0 0 20px;
    }

    #product-main .shop-header a {
        margin: 10px 0 0;
        width: 150px;
        font-size: 18px;
        height: 40px;
    }

    .product .product-img img {
        width: 100%;
        height: 300px;
    }

    .customer-head h2,
    .shop-header h2,
    #product-selling-main h2 {
        font-size: 22px;
    }

    .product-main-slider {
        padding: 0 15px;
    }

    .product-detail {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .product-detail span {
        padding: 0;
        width: auto;
    }

    .product-detail button {
        margin: 0 0;
        font-size: 14px;
    }

    .product-detail button img {
        width: 15px;
    }

    .shop-category-col {
        padding: 0;
    }

    .shop-header a {
        margin: 20px 0 0;
        height: 40px;
        width: 118px;
    }

    #shop-main,
    #customers-main,
    #product-selling-main {
        margin: 40px 0;
    }

    .banner-text .banner a {
        margin: 15px 0 0;
        height: 40px;
    }

    .banner-text .banner h1 {
        font-size: 32px;
    }

    .banner-img img {
        height: 280px;
    }

    #product-selling-main h2 {
        margin: 0 0 20px;
    }

    .filter-mix-main .filter-main {
        width: 100%;
    }

    .filter-mix-main .mix-scartch-main .mix-scartch-head {
        flex-wrap: wrap;
        gap: 10px;
        margin: 15px 0 10px;
    }

    .filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scratch-right {
        justify-content: left;
        gap: 30px;
    }

    .product-col {
        padding: 0 15px;
    }

    .product-bio-main .product-profile span {
        height: 280px;
        border: 1px solid #d5d1d1;
    }

    .product-bio-main .product-profile span img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }

    .product-bio-main .product-profile ul {
        flex-wrap: wrap;
    }

    .product-bio-main .product-profile ul li {
        width: 58px;
        height: 60px;
    }

    .product-bio-main .product-bio .quantity {
        flex-wrap: wrap;
    }

    .product-bio-main .product-bio .quantity button.cart-btn {
        width: 140px;
        height: 40px;
    }

    .product-label ul {
        /* flex-wrap: wrap; */
        gap: 3px;
        justify-content: space-between;
    }

    .product-label ul li {
        width: max-content;
        margin: 0 0 5px;
        font-size: 14px;
        padding: 0 0 5px;
    }

    .product-bio-main .product-bio span.price {
        font-size: 20px;
    }

    .product-bio-main .product-bio h2 {
        font-size: 23px;
    }


    .product-bio-main .product-bio .quantity button span {
        width: 40px;
        height: 40px;
    }

    .login-main,
    .register-main,
    .forgot-main,
    .verify-mail-main,
    .new-password-main,
    .complete-reset {
        width: 90%;
        padding: 15px;
    }

    .login-main .logo span,
    .register-main .logo span,
    .forgot-main .logo span,
    .verify-mail-main .logo span,
    .new-password-main .logo span,
    .complete-reset .logo span {
        top: -78px;
        right: 0;
    }

    .login-main .login-field .input-field,
    .register-main .login-field .input-field,
    .forgot-main .login-field .input-field,
    .verify-mail-main .login-field .input-field,
    .new-password-main .login-field .input-field {
        flex-wrap: wrap;
    }

    .login-main .login-field .input-field .input-check label,
    .register-main .login-field .input-field .input-check label {
        margin: 0 0 0 10px;
        font-size: 13px;
    }
    .login-main .login-field .input-field  a.forgot-btn {
        font-size: 13px;
        margin: 0;
    }

    .login-main .login-field .input-field .input-check input[type="checkbox"],
    .register-main .login-field .input-field input[type="checkbox"] {
        width: 15px;
        height: 15px;
    }

    .login-main .login-field .input-field a {
        margin: 10px 0 0;
    }

    .login-main .login-btn a,
    .register-main .login-btn a,
    .forgot-main .login-btn a,
    .verify-mail-main .login-btn a,
    .new-password-main .login-btn a,
    .complete-reset .login-btn a,
    .register-main .login-field .input-field .country-btn {
        height: 40px;
        font-weight: 500;
        font-size: 14px;
    }

    .login-main .login-field .input-field input,
    .register-main .login-field .input-field input,
    .register-main .login-field .input-field .select-btn,
    .forgot-main .login-field .input-field input,
    .verify-mail-main .login-field .input-field input,
    .new-password-main .login-field .input-field input {
        height: 40px;
        padding: 0 10px;
    }

    .login-main .login-field,
    .register-main .login-field,
    .forgot-main .login-field,
    .verify-mail-main .login-field,
    .new-password-main .login-field,
    .complete-reset .login-field {
        margin: 20px 0 0;
    }

    .register-main {
        height: 80vh;
        overflow-y: scroll;
    }

    .login-main .logo a img,
    .register-main .logo a img,
    .forgot-main .logo a img,
    .verify-mail-main .logo a img,
    .new-password-main .logo a img,
    .complete-reset .logo a img {
        width: 110px;
        height: auto;
    }

    .login-main .login-field .input-field .verify-otp,
    .register-main .login-field .input-field .verify-otp,
    .verify-mail-main .login-field .input-field .verify-otp {
        gap: 5px;
    }

    .verify-mail-main .login-field .input-field input {
        padding: 0 15px;
        width: 40px;
    }

    /* .create-mix-main .create-mix .create .creta-head input {
        width: 20px;
    } */

    .create-mix-main .select-category ul li {
        padding: 10px;
        font-size: 14px;
    }

    .create-mix-main .qna-main ul li {
        flex-wrap: wrap;
        padding: 15px;
    }

    .create-mix-main .qna-main ul li a {
        margin: 0 0 15px;
        font-size: 14px;
    }

    .create-mix-main .submit-btn {
        justify-content: left;
    }

    .create-mix-main .submit-btn button {
        margin: 10px 0 0;
    }

    .filter-mix-main .mix-scartch-main .mix-scratch {
        width: 100%;
        overflow: scroll;
        background-color: transparent;
        height: auto;
    }

    .mix-scratch-mobile {
        width: 430px !important;
    }

    #contact-main {
        padding: 15px 0 40px;
    }

    .contact-form form {
        padding: 15px;
        margin: 40px 0 0;
    }

    .contct-details .contact-head p {
        font-size: 16px;
    }

    .contct-details .contact-social ul li .social-left label {
        font-size: 18px;
    }

    .contct-details .contact-social ul li .social-left a,
    .contct-details .contact-social ul li .social-left p {
        font-size: 16px;
        line-height: 23px;
    }

    .contct-details .contact-social ul li {
        padding: 25px 0;
    }

    .contct-details .contact-social ul li span {
        width: 45px;
        height: 45px;
    }

    .contct-details .contact-social ul li span img {
        width: 20px;
    }

    .contact-form form .input-group input {
        height: 40px;
    }

    .next-previous-slide {
        flex-wrap: wrap;
        justify-content: center;
        margin: 32px 0 0 0;
    }

    .next-previous-slide .slides-button {
        width: 100%;
        text-align: center;
        margin: 20px 0 0;
    }

    .faq ul li {
        padding: 15px;
    }

    .faq ul li h2 {
        width: 230px;
        font-size: 15px;
    }

    .faq-head {
        margin: 0 0 30px;
    }

    .cart-item-main .cart-item {
        padding: 15px 15px 26px;
        flex-wrap: wrap;
    }

    .cart-item-main .cart-item .cart-details {
        width: 100%;
    }

    .cart-item-main .cart-item .cart-img img {
        height:90px;
        width: 89px;
    }

    .cart-item-main .cart-item .cart-details .cart-details-head {
        flex-wrap: wrap;
    }

    .cart-item-main .cart-item .cart-details .cart-details-head h3 {
        width: 100%;
    }

    .cart-item-main .cart-itme-head {
        padding: 0 15px;
    }

    .Checkout-btn button {
        height: 40px;
    }

    #cart-main {
        padding: 40px 0;
    }

    .cart-head h2 {
        margin: 0 0 20px;
    }

    .checkout-shipping-main .cart-itme-head ul {
        padding: 20px 0;
    }

    .cart-head a {
        margin: 10px 0 37px;
    }

    .checkout-shipping-main .checkout-shipping {
        padding: 21px 15px 0;
    }

    .checkout-shipping-main .checkout-btn {
        margin: 45px 0 0;
        padding: 25px 15px;
        flex-wrap: wrap;
        gap: 10px;
    }

    .cart-checkout .checkout-total {
        padding: 25px 15px;
    }

    .setting-detail .setting-view .profile {
        flex-wrap: wrap;
        gap: 15px;
        padding: 41px 0 35px 15px;
    }

    .setting-detail .setting-view .profile .upload-img {
        width: 100%;
    }

    .setting-detail .setting-view .profile .profile-img {
        width: 120px;
    }

    .setting-detail .setting-view .profile .profile-img img {
        height: 120px;
    }

    .setting-detail .information .input-group,
    .setting-view .change-password .input-group {
        flex-wrap: wrap;
    }

    .setting-detail .information .input-group .input-field {
        width: 100%;
    }

    .setting-detail .information h3,
    .setting-view .change-password h3 {
        margin: 0 0 20px;
    }

    .setting-detail .information {
        padding: 0 15px 49px 15px;
    }

    .setting-detail .setting-btn button {
        padding: 11px 20px;
    }

    .setting-detail .setting-btn {
        padding: 15px 15px 15px 0;
    }

    .profile-head h2 {
        margin: 0 0 20px;
    }

    #profile-main {
        padding: 40px 0 50px;
    }

    .setting-view .change-password .input-group .input-field {
        width: 100%;
    }

    .setting-view .change-password {
        padding: 15px;
    }

    .wishlist-main .cart-item {
        padding: 10px !important;
        border: 1px solid #e0e0e0;
        margin: 0 0 15px;
    }
    .wishlist-main .cart-item:last-child {
        border-bottom: 1px solid #e0e0e0 !important;
    }

    .wishlist-main .cart-itme-head {
        padding: 0 !important;
    }

    .my-order-main .cart-item .cart-details .cart-details-head h3 {
        margin: 0 0 10px;
    }

    .wishlist-main .cart-itme-head {
        margin: 15px 0 0;
    }

    .checkout-shipping-main .checkout-shipping .address {
        padding: 15px;
    }

    .my-order-details .order-track-main .track-path {
        flex-wrap: wrap;
    }

    .my-order-details .order-track-main .track-path li {
        width: 100%;
        text-align: left;
        margin: 0 0 20px;
    }

    .my-order-details .order-track-main .track-head {
        flex-wrap: wrap;
    }

    .my-order-details .order-track-main .track-head h5 {
        margin: 0 0 10px;
        font-size: 15px;
    }

    .my-order-details .order-track-main .track-path .circle,
    .my-order-details .order-track-main .track-path .current-circle {
        margin: 0;
        position: absolute;
        top: 3px;
    }

    .my-order-details .order-track-main .track-path li.last-status {
        text-align: left;
    }

    .my-order-details .order-track-main .track-path::after {
        display: none;
    }

    .my-order-details .order-track-main .track-path li h5 {
        margin: 0 0 10px;
    }

    .my-order-details .order-track-main .track-path li span {
        margin: 10px 0 0;
    }

    .offers-main {
        width: 90%;
        padding: 15px;
    }

    .offers-main p {
        font-size: 14px;
        line-height: 18px;
    }

    .offers-main ul li {
        padding: 5px;
    }

    .flavor-main,
    .add-sweetners-main,
    .addmix-ingredients-main {
        width: 90%;
    }

    .flavor-main .flavor-head,
    .add-sweetners-main .flavor-head,
    .addmix-ingredients-main .flavor-head {
        padding: 15px;
        align-items: self-start;
    }

    .flavor-main .flavor-select,
    .add-sweetners-main .flavor-select,
    .addmix-ingredients-main .flavor-select {
        padding: 0 15px;
    }

    .flavor-main .flavor-btn button,
    .add-sweetners-main .flavor-btn button,
    .addmix-ingredients-main .flavor-btn button {
        margin: 0 0 15px;
        height: 35px !important;
    }

    .flavor-main .flavor-btn,
    .add-sweetners-main .flavor-btn,
    .addmix-ingredients-main .flavor-btn {
        margin: 45px 0 0;
        justify-content: space-around;
        gap: 5px;
    }

    .flavor-main .flavor-select .flavor-category,
    .add-sweetners-main .flavor-select .flavor-category,
    .addmix-ingredients-main .flavor-select .flavor-category {
        margin: 0 0 20px;
        width: 45%;
    }

    .flavor-main .flavor-select .flavor-category .field input,
    .add-sweetners-main .flavor-select .flavor-category .field input,
    .addmix-ingredients-main .flavor-select .flavor-category .field input {
        width: 14px;
        height: 14px;
        margin: 3px 0 0;
    }

    .flavor-main .flavor-select .flavor-category .field label,
    .add-sweetners-main .flavor-select .flavor-category .field label,
    .addmix-ingredients-main .flavor-select .flavor-category .field label {
        font-size: 14px;
    }

    .cart-items-main {
        width: 320px;
        padding: 15px;
    }

    .cart-item-head h2 {
        font-size: 16px;
    }

    .add-address-main,
    .add-rate-main,
    .reason-cancellation-main {
        width: 300px;
    }
    .add-address-main{
        width: 90%;
    }

    .add-address-main .card-details {
        padding: 15px;
        height: 65vh;
        overflow-y: auto;
    }

    .add-address-main .head,
    .add-rate-main .head,
    .reason-cancellation-main .head {
        padding: 15px;
    }

    .add-address-main .card-btn,
    .add-rate-main .card-btn,
    .reason-cancellation-main .card-btn {
        padding: 15px;
    }

    .heading-cart {
        padding: 5px !important;
    }

    .add-btn.py {
        padding: 0 !important;
    }

    .logout-main,
    .success-main,
    .cart-main,
    .cart-sucess-main,
    .password-update-main,
    .rating-confirmation-main,
    .cancle-confirmation-main {
        width: 300px;
    }

    .mix-ingrediants-range {
        flex-wrap:nowrap;
        height: 35vh !important;
    }

    .addmix-ingredients-main .flavor-select {
        width: 100%;
        align-items: self-start;
    }

    .mix-ingrediants-range .dose {
        width: 55%;
        padding: 0;
    }

    .addmix-ingredients-main .addmix-ingredients-head {
        padding: 15px;
        flex-wrap: wrap;
        gap: 30px;
    }

    .mix-ingrediants-range .dose .range {
        margin: 0 0 30px;
        gap: 10px;
        align-items: self-start;
    }

    .addmix-ingredients-main .flavor-head .search-bar input {
        height: 40px;
    }

    .addmix-ingredients-main .flavor-head .search-bar span {
        top: 8px;
    }

    .flavor-main .flavor-head h2,
    .add-sweetners-main .flavor-head h2,
    .addmix-ingredients-main .flavor-head h2 {
        width: 100%;
        margin: 0 0 10px;
    }

    .logout-main-one {
        width: 90%;
    }

    .header .user ul {
        gap: 10px;
    }

    #header-main {
        padding: 0 15px;
    }

    .product {
        height: 415px !important;
    }

    .product span,
    .product h3,
    .product .rating {
        padding: 0;
    }

    .product-col {
        padding: 0;
    }

    .product-slider {
        margin: 0;
    }

    .product .product-img img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .product .product-img {
        padding: 15px;
    }

    .product .detail {
        margin: 0 0 20px;
        padding: 0 15px;
    }

    #product-page-main {
        padding: 0 0 45px;
    }

    .banner-text .banner {
        max-width: 100%;
    }

    .banner-text .banner h1 {
        font-weight: 28px;
    }

    .product-detail span {
        font-size: 16px;
    }

    .logout-main-one {
        height: max-content;
    }

    .gender_male-input {
        width: 100%;
        justify-content: left;
        /* flex-wrap: wrap; */
        row-gap: 20px;
        gap: 10px;
    }

    .gender_male-input div .guest,
    .gender_male-input div .login {
        width: 100%;
    }

    .gender_male-input>div {
        width: 50% !important;
    }
    .gender_male-input .input-field select {
        width: 100%;
        padding: 0;
    }

    .gender_male-input>div .guest,
    .gender_male-input>div .login {
        width: 100%;
    }

    .card-details {
        padding: 15px;
    }

    .logout-main-one .card-details h5 {
        font-size: 17px;
    }

    .logout-main-one .card-details p {
        margin-top: 10px;
        font-size: 14px;
    }

    .login-main .log-footer,
    .register-main .log-footer,
    .verify-mail-main .log-footer {
        margin: 10px 0 0;
    }

    .login-main .login-field p,
    .register-main .login-field p,
    .forgot-main .login-field p,
    .verify-mail-main .login-field p,
    .new-password-main .login-field p,
    .complete-reset .login-field p {
        margin: 9px 0 15px;
        font-size: 14px;
    }

    .css-128ynr5-control,
    .css-ulcugr-container {
        min-height: 40px;
        height: 40px;
    }

    .register-main .login-field .input-field .input-check {
        margin: 0;
    }

    .login-main .login-field h2,
    .register-main .login-field h2,
    .forgot-main .login-field h2,
    .verify-mail-main .login-field h2,
    .new-password-main .login-field h2,
    .complete-reset .login-field h2 {
        font-size: 18px;
    }

    .contact-form form h3 {
        font-size: 18px;
        margin: 0 0 15px;
    }

    .filter-mix-main {
        margin: 15px 0 0;
    }

    .filter-mix-main .mix-filter-main h2 {
        margin: 2px 0 10px;
    }

    .filter-mix-main .mix-filter-main .filter .gender {
        padding: 15px;
        flex-wrap: wrap;
        row-gap: 10px;
        width: 100%;
    }

    .filter-mix-main .mix-filter-main .filter .height-weight {
        padding: 27px 15px;
    }

    .productpage-main-slider {
        padding: 0;
    }

    .mix-main {
        padding: 15px 0 50px;
    }

    .create-mix-main .submit-btn button {
        height: 40px;
        width: 110px;
    }

    .create-mix-main .create-mix .create .creta-head img {
        width: 40px;
    }

    .create-mix-main .select-category h3,
    .create-mix-main .qna-main h3 {
        margin: 0 0 15px;
    }

    .create-mix-main .qna-main ul li .selectoption {
        margin: 10px 0 0;
    }

    .back-btn {
        margin: 0 0 15px;
    }

    .cart-head>div {
        margin: 5px 0 0;
    }

    .checkout-shipping-main .cart-itme-head ul li span {
        width: 30px;
        height: 30px;
    }

    .checkout-shipping-main .cart-itme-head ul li span img {
        width: 15px;
    }

    .address-heading {
        font-weight: 500;
    }

    .checkout-shipping-main .checkout-btn button,
    .checkout-shipping-main .checkout-btn a {
        width: 100%;
    }

    .checkout-shipping-main .checkout-shipping .add-address-btn {
        margin: 20px 0 15px;
    }
    .filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scartch-left h2 {
        /* flex-wrap: wrap; */
        width: auto;
        justify-content: space-between;
    }
    .filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scartch-left h2 span {
        width: auto;
        margin: 0 0;
    }
    .rating_review-main .input-field .input .star-icons {
        justify-content: space-between;
    }
    .rating_review-main .input-field .input .star-icons span img {
        width: 30px;
    }
    .rating_review-main {
        width: 90%;
        padding: 15px;
    }
    table tbody tr td.table-col {
        font-size: 14px;
        padding: 15px 39px !important;
    }
    table thead tr th {
        font-size: 15px;
        padding: 15px 38px;
    }
    .filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scratch-right .cart-btn .order-num {
        top: -1px;
        left: 25px;
        width: 18px;
        height: 18px;
        font-size: 10px;
    }
    .filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scratch-right .search-bar input {
        height: 35px;
    }
    .filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scartch-left h2 span img {
        width: 14px;
    }
    .filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scratch-right .cart-btn {
        padding: 0;
        top: 89%;
        right: 6%;
    }
    .filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scratch-right .cart-btn img {
        width: 18px;
    }
    .create-mix-main .create-mix .create .creta-head input::after {
        top: -2px;
    }
    .modal-overlay {
        width: 90% !important;
    }
    .modal-body h2 {
        font-size: 17px !important;
    }
    .modal-body .ingredients_details span {
        font-size: 14px !important;
    }
    .range_slider .form-range {
        width: 100% !important;
    }
    .modal-cancel-btn ,.modal-submit-btn {
        height: 35px !important;
    }
    .recommended_dose h5:nth-child(1), .modal-body .ingredients_details .dose_modal {
        font-size: 14px !important;
    }
    .modal-body {
        margin: 0 !important;
    }
    .cart-item-head {
        margin: 0 0 20px !important;
    }
    .cart_item_table table tbody tr td.table-col {
        padding: 15px 0 !important;
    }
    .modal-content {
        overflow-x: hidden;
    }
    .availableCrossIcon {
        font-size: 25px !important;
        top: 20px;
        right: 20px;
    }
    .read-more-button {
        padding: 4px !important;
        font-size: 12px !important;
    }
    .cart-react-btn {
        width: 100% !important;
        gap: 45px !important;
        position: absolute;
        left: 0 !important;
        padding: 0 15px !important;
    }
    .cart-react-btn .modal-submit-btn {
        width: 100px !important;
        margin: 0 !important;
    }
    .cart-react-btn .modal-close-btn {
        height: 35px !important;
    }
    .login-main .login-field .input-field .input-check {
        margin: 0 0;
    }
    .product-label .discription ul .rating-review-main .rating-review .product-rating {
        width: 150px;
    }
    .my-order-details .order-track-main .track-path::before {
        top: 97px;
        left: -61px;
        transform: translateX(-50%);
        width: 150px;
        transform: rotate(90deg);
    }
    .login-main .back-login a, .register-main .back-login a, .forgot-main .back-login a, .verify-mail-main .back-login a, .new-password-main .back-login a {
        font-size: 14px;
    }
    .recommended_dose, .total_price {
        margin: 180px 0 0 !important;
    }
    .add-address-main .card-details .input-field input, .add-address-main .card-details .input-field select, .add-address-main .card-details .input-field textarea {
        height: 40px;
    }
    .flavor-main .flavor-head h2, .add-sweetners-main .flavor-head h2, .addmix-ingredients-main .flavor-head h2 {
        width: auto;
        font-size: 16px;
    }
    .addmix-ingredients-main .flavor-select .flavor-category .field input[type="checkbox"]::before, .flavor-main .flavor-select .flavor-category .field input[type="checkbox"]::before, .add-sweetners-main .flavor-select .flavor-category .field input[type="checkbox"]::before {
        line-height: 14px;
    }
    .flavor-main .flavor-head button, .addmix-ingredients-main .flavor-head button {
        margin: 3px 0 0;
    }
    /* .mix-ingrediants-range .dose {
        width: 100%;
    } */
    .mix-ingrediants-range .dose .range .input-range input {
        width: 100% !important;
    }
    .mix-ingrediants-range .dose .range .input-range {
        width: 100%;
        margin-top: 0;
    }
    .mix-ingrediants-range .dose .range .range-count {
        width: max-content;
    }
    .mix-ingrediants-range .dose .range .range-count input {
        width: 100%;
        height: 21px;
        font-size: 10px;
        padding: 0;
    }
    .flavor-main .flavor-select-main .flavor-select {
        padding: 20px 0 100px 15px;
    }
    .flavor-main .flavor-select .flavor-category .field .mode-select button {
        width: max-content;
        height: 23px;
        padding: 3px 5px;
        font-size: 10px;
    }
    .flavor-main .flavor-btn button.cancle-btn, .add-sweetners-main .flavor-btn button.cancle-btn, .addmix-ingredients-main .flavor-btn button.cancle-btn {
        width: 48%;
    }
     
    .flavor-main .flavor-btn button.save-btn, .add-sweetners-main .flavor-btn button.save-btn, .addmix-ingredients-main .flavor-btn button.save-btn,
    .flavor-main .flavor-btn .sweetner-btn .sweetback-btn, .add-sweetners-main .flavor-btn .sweetner-btn .sweetback-btn {
        width: 50%;
    }
    .flavor-main .flavor-select .flavor-category .field, .add-sweetners-main .flavor-select .flavor-category .field, .addmix-ingredients-main .flavor-select .flavor-category .field {
        gap: 0;
        justify-content: unset;
    } 
    .flavor-main .flavor-select .flavor-category .field {
        gap: 20px;
    }
    .flavor-main .flavor-head, .flavor-main .flavor-btn .sweetner-btn, .add-sweetners-main .flavor-btn .sweetner-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .cart-sucess-main.active {
        width: 300px;
        padding: 15px 0 24px;
    }
    .cart-sucess-main  p {
        padding: 0 10px;
    }
    /* .cart-item-main .cart-item .cart-details .cart-quantity {
        flex-wrap: wrap;
    } */
    /* .cart-item-main .cart-item .cart-details .cart-quantity .cart-item-remove {
        width: 100%;
        margin: 15px 0 0;
    } */
    .addmix-ingredients-main .flavor-head .gender-btn button {
        font-size: 14px;
        font-weight: normal;
    }
    .mix-ingrediants-range .dose .range .range-count span img {
        width: 13px;
    }
    .my-order-details .cart-item {
        padding: 0 0 26px !important;
        position: relative;
    }
    .setting-detail .information .input-group .input-field input, .setting-detail .information .input-group .input-field select, .setting-view .change-password .input-group .input-field input {
        height: 40px;
    }
    .wishlist-main .cart-item .cart-details p {
        margin: 10px 0 15px;
    }
    .wishlist-main .cart-item .cart-details .cart-quantity {
        margin: 0 0 13px;
    }
    .flavor-main .flavor-btn button.cancle-btn, .add-sweetners-main .flavor-btn button.cancle-btn {
        width: 100%;
        margin: 0 0 5px;
    }
    .flavor-main .flavor-btn button , .add-sweetners-main  .flavor-btn button{
        margin: 0 0 5px;
    }
    .cart-item-main .cart-item .cart-details .cart-quantity .quantity-btn button {
        width: 20px;
        height: 20px;
    }
    .cart-item-main .cart-item .cart-details .cart-quantity .quantity-btn span {
        margin: 0;
    }
    .cart-item-main .cart-item .cart-details p {
        margin: 10px 0 15px;
    }
    .my-order-details .order-track-main .track-path li span {
        margin: 0 45px 15px;
    }
    .my-order-details .order-track-main .track-path li h5 {
        margin: 0 45px 10px;
    }
    .payment-form-overlay .payment-container {
        width: 90%;
    }
    .order-confirm-main {
        width: 90%;
        padding: 30px 38px 20px;
    }
    .product-col-main {
        width: 100% !important;
        padding: 0 15px;
    }
    .cart-item-main {
        padding: 10px 15px;
    }
    /* .cart-item-main .cart-item {
        margin: 5px 0 0;
    } */
    .verify-mail-main .login-field .input-field .verify-otp {
        width: 100%;
    }
    .footer-top-main {
        margin: 0 0 5px;
        box-shadow: unset;
    }
    .flavor-main .flavor-select .flavor-category .field .mode-select {
        margin: 0 10px 0 0;
        width: 100%;
        justify-content: right;
    }
    .flavor-main .flavor-select .flavor-category{
        width: 100%;
    }
    .product-label .discription ul .all-reviews .customer-review {
        width: 100% !important;
    }
    .filter-mix-main .mix-filter-main {
        width: 100%;
    }
    .create-mix-main .create-mix .create p {
        font-size: 14px;
    }
    .total-value {
        margin: 75px 0 0 !important;
    }
    .ingredient-dose {
        margin: 20px 0 0 !important;
    } 
    .addmix-ingredients-main .flavor-select {
        padding: 0 0 0 15px;
    }
    .add-address-main .card-btn {
        position: fixed;
        bottom: 0;
    }
    .order-filter-main {
        width: 80%;
    }
    .star-ratings {
        display: flex  !important;
        justify-content: center !important;
    }
    .price {
        width: 20%;
    }
    .product-page-only .product-col-main {
        padding: 0;
    }
    .product-bio-main .product-profile ul li img {
        object-fit: contain;
        width: 100%;
    }
    .read-more-button {
        transition: all 1s ease-in-out;
    }
    .order-filter-main .filter-btn {
        padding: 25px 15px;
        gap: 10px;
    }
    .close-btn {
        top: 28px;
    }
    .filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scratch-right .search-bar {
        width: 100%;
    }
    .filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scartch-left {
        margin: 0 0 12px;
    }
    .filter-mix-main .mix-scartch-main .mix-scartch-head .mix-scratch-right p {
        font-weight: 400;
    }

    .filter-mix-main .mix-filter-main .filter .product-category-main button img {
        display: unset;
    }
    .setting-view .change-password .input-group .input-field svg {
        position: absolute;
        right: 13px;
        top: 32px ;
        font-size: 18px;
      }
    .give_review-button {
        font-weight: 500;
        position: absolute;
        top: 10px;
        right: 0;
        font-family: "Rubik", sans-serif;
    }
    .my-order-details .cart-item .cart-details .cart-details-head h3 {
        width: auto;
    }
    .wishlist-main .cart-itme-head span {
        font-size: 16px;
    }
    .my-order-details .order-track-main .track-path.active::before {
        top: 60px;
        left: -22px;
        width: 75px;
        transform: rotate(90deg);
    }
    .mobile-hide-banner, .bach-btn-hide {
        display: none;
    }
    .text-for-mobile {
        position: static;
        transform: unset;
        padding: 13px 0 0;
        background-color: #f2f2f2;
    }
    .text-for-mobile .banner h1 {
        font-weight:bold;
        font-size: 28px !important;
        color: #333;
    }
    .contact-banner {
        text-align: left;
    }
    #product-main {
        padding: 15px 0  40px;
    }
    .found-product-main {
        overflow: hidden;
    }
    .found-slider {
        padding: 0 15px;
    }
    .extra-ingredients-input {
        width: 85px;
    }
    .product-hide .product-col {
        width: 100%;
        padding: 0 15px;
    }
}